import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB3V2-U2-P17-E1",
    audio: "Audios/P17/audio.e1.p17.mp3",
    video: "/img/FriendsPlus/Page16/Video/Unit02.mp4",
    component: UI,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page17/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page17/E1/2.jpg",
          audioUrl: "Audios/P16/tieude.e3.p16.mp3",
        },
        { url: "img/FriendsPlus/Page17/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page16/E3/4.jpg",
          audioUrl: "Audios/P16/hinh1.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page16/E3/5.jpg",
          audioUrl: "Audios/P16/hinh2.e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page16/E3/6.jpg",
          audioUrl: "Audios/P16/hinh3.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page16/E3/7.jpg",
          audioUrl: "Audios/P16/hinh4.e3.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB3V2-U2-P17-E2",
    audio: "Audios/P17/audio.e2.p17.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page17/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page17/E2/2.jpg",
          audioUrl: "Audios/P17/tieude.e2.p17.mp3",
        },
        { url: "img/FriendsPlus/Page17/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page17/E2/4.jpg" }],
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB3V2-U2-P17-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [{ url: "img/FriendsPlus/Page17/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page17/E3/2.jpg" }],
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB3V2-U2-P17-E4",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page17/E4/Key/answerKey.png",
    video: "",
    fontSize: 30,
    textAlign: "center",
    component: D1,
    questionImage: [
      [{ url: "img/FriendsPlus/Page17/E4/1.jpg" }],
      [{ url: "img/FriendsPlus/Page17/E4/2.jpg" }],
      [
        { url: "img/FriendsPlus/Page17/E4/3.jpg" },
        {
          url: "img/FriendsPlus/Page17/E4/4.jpg",
          input: true,
          answer: "her",
        },
        { url: "img/FriendsPlus/Page17/E4/5.jpg" },
        { url: "img/FriendsPlus/Page17/E4/6.jpg", input: true, answer: "his" },
      ],
      [{ url: "img/FriendsPlus/Page17/E4/7.jpg" }],
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB3V2-U2-P17-E3",
    audio: "",
    video: "",
    component: UI,
    questionImage: [
      [{ url: "img/FriendsPlus/Page17/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page17/E3/2.jpg" }],
    ],
  },
};

export default json;
