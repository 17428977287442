import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P92-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page92/E1/1.jpg" }],
      [{ url: "img/FriendsPlus/Page92/E2/2.jpg" }],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P92-E2",
    audio: "Audios/P92/audio-e2-p92.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page92/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page92/E2/b.jpg",
          audioUrl: "Audios/P92/tieude-e2-p92.mp3",
        },
        { url: "img/FriendsPlus/Page92/E2/c.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page92/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page92/E2/2.jpg",
          audioUrl: "Audios/P92/hinh1.mp3",
        },
        { url: "img/FriendsPlus/Page92/E2/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page92/E2/4.jpg",
          audioUrl: "Audios/P92/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page92/E2/5.jpg",
          audioUrl: "Audios/P92/hinh2.mp3",
        },
        {
          url: "img/FriendsPlus/Page92/E2/6.jpg",
          audioUrl: "Audios/P92/hinh3.mp3",
        },
      ],
      [
        { url: "img/FriendsPlus/Page92/E2/7.jpg" },
        {
          url: "img/FriendsPlus/Page92/E2/8.jpg",
          audioUrl: "Audios/P92/hinh4.mp3",
        },
        {
          url: "img/FriendsPlus/Page92/E2/9.jpg",
          audioUrl: "Audios/P92/hinh5.mp3",
        },
      ],
      [{ url: "img/FriendsPlus/Page92/E2/10.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P92-E3",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page92/E3/Key/answerKey.png",
    inputSize: 240,
    titleImage: "img/FriendsPlus/Page92/E3/1.jpg",
    questionImage: [],
    checkUppercase: true,
    maxLength: 11,
    // hintBox: [
    //   {
    //     src: [
    //       "<s style='color: rgb(0 188 212)'>brushing my hair</s>",
    //       "washing the car",
    //       "making cakes",
    //       "taking photos",
    //     ],
    //     borderColor: "#468dcb",
    //     width: 600,
    //   },
    // ],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page92/E3/2.jpg'  />
        <div style='display: flex'>
            <div  >
             <div style='margin-right:'><b>1.</b> What class is Phong in?<br> #</div>        
             <div style='margin-right:'><b>2. </b> How many boys are there in his class?<br> #</div>        
             <div style='margin-right:'><b>3. </b> Where is his classroom?<br> #</div>        
             <div style='margin-right:'><b>4. </b> What is the name of his teacher?<br> #</div>        
             <div style='margin-right:'><b>5. </b> What is on the wall of the classroom?<br> #</div>        
             <div style='margin-right:'><b>6. </b> Is Phong happy at school?<br> #</div>        
            </div >
          <div style='margin-left:-5px'> <img src='img/FriendsPlus/Page92/E3/3.jpg'  /></div>
        </div>
        
        
        
        `,
        answer: [
          "3C",
          "14",
          "upstairs",
          "Miss Loan",
          "pictures",
          "Yes, he is.",
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P92-E4",
    audio: "",
    video: "",
    component: T6,
    inputSize: 100,
    titleImage: "img/FriendsPlus/Page92/E4/1.jpg",
    questionImage: [],
    hideBtnFooter: true,
    textareaStyle: { width: 800 },
    // hintBox: [
    //   {
    //     src: [
    //       "<s style='color: rgb(0 188 212)'>brushing my hair</s>",
    //       "washing the car",
    //       "making cakes",
    //       "taking photos",
    //     ],
    //     borderColor: "#468dcb",
    //     width: 600,
    //   },
    // ],
    questions: [
      {
        title: `
        
       
        <div style=''>
                    
            <textarea id="0" rows="8"></textarea>       
                   
        </div>
        
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
