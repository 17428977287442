import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "SB3V2-U4-P37-E1",
    audio: "Audios/P37/audio-e1-p37.mp3",
    video: "",
    component: DesignUnderLine,
    // component: DesignUnderLine1,
    totalInput: 6,
    exerciseKey: "img/FriendsPlus/Page37/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page37/E1/29.jpg" },
        {
          url: "img/FriendsPlus/Page37/E1/30.jpg",
          audioUrl: "Audios/P37/tieude-e1-p37.mp3",
        },
        { url: "img/FriendsPlus/Page37/E1/31.jpg" },
      ],
      [
        // row1
        { url: "img/FriendsPlus/Page37/E1/1.jpg" },
      ],
      [
        // row2
        { url: "img/FriendsPlus/Page37/E1/2.jpg" },
        { url: "img/FriendsPlus/Page37/E1/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page37/E1/4.jpg" },
        { url: "img/FriendsPlus/Page37/E1/5.jpg", input: 2 },
      ],
      [
        { url: "img/FriendsPlus/Page37/E1/6.jpg" },
        { url: "img/FriendsPlus/Page37/E1/7.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page37/E1/8.jpg" },
        // row3
        { url: "img/FriendsPlus/Page37/E1/9.jpg", input: 2, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page37/E1/10.jpg" }],
      [
        { url: "img/FriendsPlus/Page37/E1/11.jpg" },
        { url: "img/FriendsPlus/Page37/E1/12.jpg", input: 3 },
        { url: "img/FriendsPlus/Page37/E1/13.jpg" },
        { url: "img/FriendsPlus/Page37/E1/14.jpg", input: 4, isCorrect: true },
      ],
      [
        { url: "img/FriendsPlus/Page37/E1/15.jpg" },
        // row4
        { url: "img/FriendsPlus/Page37/E1/16.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page37/E1/17.jpg" },
        { url: "img/FriendsPlus/Page37/E1/18.jpg", input: 4 },
      ],
      [{ url: "img/FriendsPlus/Page37/E1/19.jpg" }],
      [
        { url: "img/FriendsPlus/Page37/E1/20.jpg" },
        { url: "img/FriendsPlus/Page37/E1/21.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page37/E1/22.jpg" },
        // row5
        { url: "img/FriendsPlus/Page37/E1/23.jpg", input: 6 },
      ],
      [
        { url: "img/FriendsPlus/Page37/E1/24.jpg" },
        { url: "img/FriendsPlus/Page37/E1/25.jpg", input: 5 },
        { url: "img/FriendsPlus/Page37/E1/26.jpg" },
        { url: "img/FriendsPlus/Page37/E1/27.jpg", input: 6, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page37/E1/28.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB3V2-U4-P37-E2",
    audio: "",
    video: "",
    component: UI,
    exerciseKey: "",
    recorder: true,
    questionImage: [
      [{ url: "img/FriendsPlus/Page37/E2/1.jpg" }],
      [{ url: "img/FriendsPlus/Page37/E2/2.jpg" }],
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB3V2-U4-P37-E3",
    audio: "",
    video: "",
    component: UI,
    exerciseKey: "",
    recorder: true,
    questionImage: [[{ url: "img/FriendsPlus/Page37/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 4",
    id: "SB3V2-U4-P37-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    hideBtnFooter: true,
    stylesTextInput: { borderBottom: "dash" },
    maxLength: 13,
    inputSize: 200,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page37/E4/1.jpg' />
          <div style='margin:30px 0px 30px 50px'><b>1.</b> I like #. They’re #.</div>
          <div style='margin: 30px 0px 30px 50px' ><b>2.</b> I don’t like #. They’re #.</div>
                
        `,
        answer: ["How often", "Who", "Where", "When", "What", "Why"],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB3V2-U4-P37-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page37/E5/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    questionImage: [],
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 300,
    maxLength: 21,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page37/E5/1.jpg' />
        <div style='display: flex; margin-top: 10px '>
          <div style='margin:0px 0px 0px 50px'><b>1.</b> is / lion / big. / The <br> #</div>
          <div style='margin-left: 100px'><b>3.</b> giraffes / The / tall. / are<br>#</div>
        </div>
        <div style='display: flex '>
          <div style='margin:0px 0px 0px 50px '><b>2.</b> bird / green. / is / That<br>#</div>
          <div style='margin:0px 0px 0px 100px;'><b>4.</b> like / I / monkeys. / don’t<br>#</div>
        </div>
        
        `,
        answer: [
          "The lion is big.",
          "The giraffes are tall.",
          "That bird is green.",
          "I don't like monkeys.",
        ],
      },
    ],
  },
};

export default json;
