import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "SB3V2-U4-P32-E1",
    audio: "Audios/P32/audio-e1-p32.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page32/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page32/E1/b.jpg",
          audioUrl: "Audios/P32/tieude-e1-p32.mp3",
        },
        { url: "img/FriendsPlus/Page32/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page32/E2/1.jpg",
          audioUrl: "Audios/P32/elephant.mp3",
        },
        { url: "img/FriendsPlus/Page32/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page32/E2/3.jpg",
          audioUrl: "Audios/P32/giraffe.mp3",
        },
        { url: "img/FriendsPlus/Page32/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page32/E2/5.jpg",
          audioUrl: "Audios/P32/monkey.mp3",
        },
        { url: "img/FriendsPlus/Page32/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page32/E2/7.jpg",
          audioUrl: "Audios/P32/big.mp3",
        },
        { url: "img/FriendsPlus/Page32/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page32/E2/9.jpg",
          audioUrl: "Audios/P32/tall.mp3",
        },
        { url: "img/FriendsPlus/Page32/E2/10.jpg" },
        {
          url: "img/FriendsPlus/Page32/E2/11.jpg",
          audioUrl: "Audios/P32/little.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "SB3V2-U4-P32-E2",
    audio: "Audios/P32/audio-e2-p32.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page32/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page32/E2/b.jpg",
          audioUrl: "Audios/P32/tieude-e2-p32.mp3",
        },
        { url: "img/FriendsPlus/Page32/E2/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page32/E2/1.jpg",
          audioUrl: "Audios/P32/elephant.mp3",
        },
        { url: "img/FriendsPlus/Page32/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page32/E2/3.jpg",
          audioUrl: "Audios/P32/giraffe.mp3",
        },
        { url: "img/FriendsPlus/Page32/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page32/E2/5.jpg",
          audioUrl: "Audios/P32/monkey.mp3",
        },
        { url: "img/FriendsPlus/Page32/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page32/E2/7.jpg",
          audioUrl: "Audios/P32/big.mp3",
        },
        { url: "img/FriendsPlus/Page32/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page32/E2/9.jpg",
          audioUrl: "Audios/P32/tall.mp3",
        },
        { url: "img/FriendsPlus/Page32/E2/10.jpg" },
        {
          url: "img/FriendsPlus/Page32/E2/11.jpg",
          audioUrl: "Audios/P32/little.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "SB3V2-U4-P32-E3",
    audio: "Audios/P32/audio-e3-p32.mp3",
    video: "/img/FriendsPlus/Page32/Video/Unit04.mp4",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Look at the calendar again. Talk about Hoa.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page32/E3/a.jpg" },
        {
          url: "img/FriendsPlus/Page32/E3/b.jpg",
          audioUrl: "Audios/P32/tieude-e3-p32.mp3",
        },
        { url: "img/FriendsPlus/Page32/E3/c.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page32/E3/1.jpg",
          audioUrl: "Audios/P32/hinh1-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page32/E3/2.jpg",
          audioUrl: "Audios/P32/hinh2-e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page32/E3/3.jpg",
          audioUrl: "Audios/P32/hinh3-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page32/E3/4.jpg",
          audioUrl: "Audios/P32/hinh4-e3.mp3",
        },
      ],
    ],
  },
};

export default json;
