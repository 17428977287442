import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "Stater",
    id: "SB3V2-Stater-P8-E1",
    audio: "Audios/P8/audio.e1.p8.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page8/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page8/E1/b.jpg",
          audioUrl: "Audios/P8/tieude.e1.p8.mp3",
        },
        { url: "img/FriendsPlus/Page8/E1/c.jpg" },
      ],

      [
        {
          url: "img/FriendsPlus/Page8/E1/1.jpg",
          audioUrl: "Audios/P8/red.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/2.jpg",
          audioUrl: "Audios/P8/brown.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/3.jpg",
          audioUrl: "Audios/P8/pink.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/4.jpg",
          audioUrl: "Audios/P8/green.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/5.jpg",
          audioUrl: "Audios/P8/black.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/6.jpg",
          audioUrl: "Audios/P8/yellow.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/7.jpg",
          audioUrl: "Audios/P8/orange.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/8.jpg",
          audioUrl: "Audios/P8/blue.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Stater",
    id: "SB3V2-Stater-P8-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page8/E2/1.jpg" }],
      [{ url: "img/FriendsPlus/Page8/E2/2.jpg" }],
    ],
  },
  3: {
    unit: "Stater",
    id: "SB3V2-Stater-P8-E3",
    audio: "Audios/P8/audio.e3.p8.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page8/E3/4.jpg" },
        {
          url: "img/FriendsPlus/Page8/E3/5.jpg",
          audioUrl: "Audios/P8/tieude.e3.p8.mp3",
        },
        { url: "img/FriendsPlus/Page8/E3/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page8/E3/2.jpg" }],
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB3V2-U3-P31-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [[{ url: "img/FriendsPlus/Page31/E4/1.jpg" }]],
  },
};

export default json;
