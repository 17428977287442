import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 3",
    id: "SB3V2-U3-P28-E1",
    audio: "Audios/P28/P28-E1.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/E1/Key/answerKey.png",
    component: DesignUnderLine,
    totalInput: 3,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page28/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page28/E1/2.jpg",
          audioUrl: "Audios/P28/tieude-e1-28.mp3",
        },

        { url: "img/FriendsPlus/Page28/E1/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page28/E1/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page28/E1/5.jpg" },
        { url: "img/FriendsPlus/Page28/E1/6.jpg", input: 1 },
        { url: "img/FriendsPlus/Page28/E1/7.jpg" },
        { url: "img/FriendsPlus/Page28/E1/8.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page28/E1/9.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page28/E1/10.jpg" }],
      [
        { url: "img/FriendsPlus/Page28/E1/11.jpg" },
        { url: "img/FriendsPlus/Page28/E1/12.jpg", input: 2 },
        { url: "img/FriendsPlus/Page28/E1/13.jpg" },
        { url: "img/FriendsPlus/Page28/E1/14.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page28/E1/15.jpg" },
        { url: "img/FriendsPlus/Page28/E1/16.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page28/E1/17.jpg" },
        { url: "img/FriendsPlus/Page28/E1/18.jpg", input: 3 },
        { url: "img/FriendsPlus/Page28/E1/19.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page28/E1/20.jpg" }],
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB3V2-U3-P28-E2",
    audio: "",
    video: "",
    component: T6,
    inputSize: 130,
    padding: 0,
    textAlign: "center",
    hideRulesRequired: true,
    exerciseKey: "img/FriendsPlus/Page28/E2/Key/answerKey.png",

    maxLength: 5,
    titleImage: "",
    questionImage: [],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page28/E2/1.jpg' width='100%' />
          <div style='display: flex; margin: 30px 20px 0px 70px;'>
            <div>
              <div style='margin-bottom: 30px'><b>1.</b> 7 <u style='color: rgb(0, 173, 238);text-decoration-color: black;'>&emsp;seven&emsp;</u></div>
              <div style='margin-bottom: 30px'><b>2.</b> three #</div>
              <div><b>3.</b> 2 #</div>
            </div>
            <div style='margin-left: 200px;'>
              <div style='margin-bottom: 30px'><b>4.</b> five #</div>
              <div style='margin-bottom: 30px'><b>5.</b> 9 #</div>
              <div><b>6.</b> eight #</div>
            </div>
          </div>
        `,
        answer: ["3", "two", "5", "nine", "8"],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB3V2-U3-P28-E3",
    audio: "",
    video: "",
    component: T6,
    maxLength: 5,
    inputSize: 117,
    isHiddenCheck: true,
    padding: 0,
    exerciseKey: "img/FriendsPlus/Page28/E3/Key/answerKey.png",

    textAlign: "center",
    hideRulesRequired: true,
    titleImage: "",
    questionImage: [],
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <img src='img/FriendsPlus/Page28/E3/1.jpg' width='100%' />
            <div style='position: absolute; top: 350px; left: 846px;'>#</div>
            <div style='position: absolute; top: 662px; left: 266px;'>#</div>
            <div style='position: absolute; top: 662px; left: 752px;'>#</div>
          </div>
        `,
        answer: ["IN", "on", "under"],
      },
    ],
  },
};

export default json;
