import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Fluency Time! 3",
    id: "SB3V2-FT3-P74-E1",
    audio: "Audios/P74/audio-e1-p74.mp3",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page74/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page74/E1/b.jpg",
          audioUrl: "Audios/P74/tieude-e1-p74.mp3",
        },
        { url: "img/FriendsPlus/Page74/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page74/E1/1.jpg",
          audioUrl: "Audios/P74/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page74/E1/2.jpg",
          audioUrl: "Audios/P74/hinh2.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Fluency Time! 3",
    id: "SB3V2-FT3-P74-E2",
    audio: "Audios/P74/audio-e2-p74.mp3",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page74/E2/Key/answerKey.png",
    isAllowSubmit: true,
    maxLength: 1,
    totalInput: 5,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page74/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page74/E2/b.jpg",
          audioUrl: "Audios/P74/tieude-e2-p74.mp3",
        },
        { url: "img/FriendsPlus/Page74/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page74/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page74/E2/2.jpg" },
        { url: "img/FriendsPlus/Page74/E2/3.jpg", input: true, answer: "3" },
        { url: "img/FriendsPlus/Page74/E2/4.jpg" },
        { url: "img/FriendsPlus/Page74/E2/5.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page74/E2/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page74/E2/7.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Fluency Time! 3",
    id: "SB3V2-FT3-P74-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: true,
    maxLength: 1,
    totalInput: 5,

    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page74/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page74/E3/2.jpg" }],
    ],
  },
};

export default json;
