import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P56-E1",
    audio: "Audios/P56/audio-e1-p55.mp3",
    video: "",
    component: UI,
    // recorder: true,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page56/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page56/E1/b.jpg",
          audioUrl: "Audios/P56/tieude-e1-p55.mp3",
        },
        { url: "img/FriendsPlus/Page56/E1/c.jpg" },
      ],
      [
        // Column2
        {
          url: "img/FriendsPlus/Page56/E1/1.jpg",
          audioUrl: "Audios/P56/T-shirt.mp3",
        },
        { url: "img/FriendsPlus/Page56/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page56/E1/3.jpg",
          audioUrl: "Audios/P56/dress.mp3",
        },
        { url: "img/FriendsPlus/Page56/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page56/E1/5.jpg",
          audioUrl: "Audios/P56/hat.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P56-E2",
    audio: "Audios/P56/audio-e2-p55.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        // Column1
        { url: "img/FriendsPlus/Page56/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page56/E2/2.jpg",
          audioUrl: "Audios/P56/tieude-e2-p55.mp3",
        },
        { url: "img/FriendsPlus/Page56/E2/3.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page56/E3/2.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P56-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page56/E3/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page56/E3/2.jpg" },
      ],
    ],
  },
};

export default json;
