import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P61-E1",
    audio: "Audios/P60/audio-e3-p60.mp3",
    video: "/img/FriendsPlus/Page60/Video/Unit08.mp4",
    component: UI,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page61/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page61/E1/2.jpg",
          audioUrl: "Audios/P60/tieude-e3-p60.mp3",
        },
        { url: "img/FriendsPlus/Page61/E1/3.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page60/E3/1.jpg",
          audioUrl: "Audios/P60/hinh1-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E3/2.jpg",
          audioUrl: "Audios/P60/hinh2-e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page60/E3/3.jpg",
          audioUrl: "Audios/P60/hinh3-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E3/4.jpg",
          audioUrl: "Audios/P60/hinh4-e3.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P61-E2",
    audio: "Audios/P61/audio-e2-p61.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page61/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page61/E2/b.jpg",
          audioUrl: "Audios/P61/tieude-e2-p61.mp3",
        },
        { url: "img/FriendsPlus/Page61/E2/c.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page61/E2/1.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page61/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page61/E2/3.jpg",
          audioUrl: "Audios/P61/hinh1-e2.mp3",
        },
        { url: "img/FriendsPlus/Page61/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page61/E2/5.jpg",
          audioUrl: "Audios/P61/hinh2-e2.mp3",
        },
        { url: "img/FriendsPlus/Page61/E2/6.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page61/E2/7.jpg",
        },
      ],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P61-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page61/E3/1.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page61/E3/2.jpg" },
      ],
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P61-E4",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page61/E4/Key/answerKey.png",
    inputSize: 116,
    maxLength: 8,
    fontSize: 25,
    textAlign: "center",
    checkDuplicated: true,
    titleImage: "img/FriendsPlus/Page61/E4/1.jpg",
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page61/E3/2.jpg'>
        <div style='display: flex ; justify-content: space-around'>
          <div><b> 1.</b> He is # music.</div>
          <div><b> 2.</b> She is #.</div>
          <div><b> 3.</b> She is #.</div>
        </div>
        `,
        answer: ["playing", "dancing", "sleeping"],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 8",
    id: "SB5-U8-P61-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page61/E5/1.jpg" },
      ],
    ],
  },
};

export default json;
