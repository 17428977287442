import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "SB3V2-U6-P45-E1",
    audio: "Audios/P44/audio-e3-p44.mp3",
    video: "/img/FriendsPlus/Page44/Video/Unit06.mp4",
    component: UI,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page45/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page45/E1/2.jpg",
          audioUrl: "Audios/P44/tieude-e3-p44.mp3",
        },
        { url: "img/FriendsPlus/Page45/E1/3.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page44/E3/1.jpg",
          audioUrl: "Audios/P44/hinh1-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E3/2.jpg",
          audioUrl: "Audios/P44/hinh2-e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page44/E3/3.jpg",
          audioUrl: "Audios/P44/hinh3-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E3/4.jpg",
          audioUrl: "Audios/P44/hinh4-e3.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 6",
    id: "SB3V2-U6-P45-E2",
    audio: "Audios/P45/audio-e2-p45.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page45/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page45/E2/b.jpg",
          audioUrl: "Audios/P45/tieude-e2-p45.mp3",
        },
        { url: "img/FriendsPlus/Page45/E2/c.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page45/E2/1.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page45/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page45/E2/3.jpg",
          audioUrl: "Audios/P45/hinh1-e2.mp3",
        },
        { url: "img/FriendsPlus/Page45/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page45/E2/5.jpg",
          audioUrl: "Audios/P45/hinh2-e2.mp3",
        },
        { url: "img/FriendsPlus/Page45/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page45/E2/7.jpg",
          audioUrl: "Audios/P45/hinh3-e2.mp3",
        },
        { url: "img/FriendsPlus/Page45/E2/8.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page45/E2/9.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 6",
    id: "SB3V2-U6-P45-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        // Column5
        { url: "img/FriendsPlus/Page45/E3/1.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page45/E3/2.jpg" },
      ],
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB3V2-U6-P45-E6",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    questionImage: [],
    stylesTextInput: { borderBottom: "dash" },
    maxLength: 4,
    exerciseKey: "img/FriendsPlus/Page45/E4/Key/answerKey.png",
    inputSize: 100,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page45/E4/1.jpg' />
          <div style='display:flex'>
            <div style='margin:30px 0px 30px 50px'><b>1.</b> He# curly hair.</div>
            
            <div style='margin: 30px 0px 30px 50px' ><b>3.</b> She#blue eyes.</div>
          </div>
          <div style='display:flex'>
            <div style='margin: 30px 0px 30px 50px' ><b>2.</b> I# straight hair.</div>
            <div style='margin: 30px 0px 30px 46px' ><b>4.</b> I#black hair.</div>
          </div> 
          
        `,
        answer: ["has", "has", "have", "have"],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 6",
    id: "SB3V2-U6-P45-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page45/E5/1.jpg" },
      ],
    ],
  },
};

export default json;
