import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P79-E1",
    audio: "Audios/P79/audio-e1-p79.mp3",
    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page79/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page79/E1/b.jpg",
          audioUrl: "Audios/P79/tieude-e1-p79.mp3",
        },
        { url: "img/FriendsPlus/Page79/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page79/E1/1.jpg",
          audioUrl: "Audios/P79/oa.mp3",
        },
        {
          url: "img/FriendsPlus/Page79/E1/2.jpg",
          audioUrl: "Audios/P79/boat.mp3",
        },
        {
          url: "img/FriendsPlus/Page79/E1/3.jpg",
          audioUrl: "Audios/P79/goat.mp3",
        },
        {
          url: "img/FriendsPlus/Page79/E1/4.jpg",
          audioUrl: "Audios/P79/oy.mp3",
        },
        {
          url: "img/FriendsPlus/Page79/E1/5.jpg",
          audioUrl: "Audios/P79/toy.mp3",
        },
        {
          url: "img/FriendsPlus/Page79/E1/6.jpg",
          audioUrl: "Audios/P79/boy.mp3",
        },
      ],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P79-E2",
    audio: "Audios/P79/audio-e2-p79.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page79/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page79/E2/b.jpg",
          audioUrl: "Audios/P79/tieude-e2-p79.mp3",
        },
        { url: "img/FriendsPlus/Page79/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page79/E2/2.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P79-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page79/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page79/E2/2.jpg" }],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P79-E4",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page79/E4/Key/answerKey.png",
    inputSize: 100,
    titleImage: "img/FriendsPlus/Page79/E4/5.jpg",
    questionImage: [],
    maxLength: 4,
    // hintBox: [
    //   {
    //     src: [
    //       "<s style='color: rgb(0 188 212)'>brushing my hair</s>",
    //       "washing the car",
    //       "making cakes",
    //       "taking photos",
    //     ],
    //     borderColor: "#468dcb",
    //     width: 600,
    //   },
    // ],
    questions: [
      {
        title: `
        
        <div style='display:flex; margin: 0px 0px 10px 50px'>
            <div style='margin-right:50px'>
              <img src='img/FriendsPlus/Page79/E4/1.jpg'>
              <div style='margin-left: 130px'>#</div>
            </div>        
            <div>
              <img src='img/FriendsPlus/Page79/E4/2.jpg'>
              <div style='margin-left: 130px'>#</div>
            </div>        
                   
        </div>
        <div style='display:flex; margin: 0px 0px 10px 50px'>
            <div style='margin-right:50px'>
              <img src='img/FriendsPlus/Page79/E4/3.jpg'>
              <div style='margin-left: 130px'>#</div>
            </div>        
            <div>
              <img src='img/FriendsPlus/Page79/E4/4.jpg'>
              <div style='margin-left: 130px'>#</div>
            </div>        
                   
        </div>
        
        
        `,
        answer: ["boat ", "goat ", "toy", "boy"],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P79-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page79/E5/1.jpg" }],
    ],
  },
};

export default json;
