import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 3",
    id: "SB3V2-U3-P31-E1",
    audio: "Audios/P31/audio-e1-p31.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page31/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page31/E1/2.jpg",
          audioUrl: "Audios/P31/tieude-e1-p31.mp3",
        },
        { url: "img/FriendsPlus/Page31/E1/3.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page31/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page31/E1/5.jpg",
          audioUrl: "Audios/P31/paint.mp3",
        },
        {
          url: "img/FriendsPlus/Page31/E1/6.jpg",
          audioUrl: "Audios/P31/light blue.mp3",
        },
        {
          url: "img/FriendsPlus/Page31/E1/7.jpg",
          audioUrl: "Audios/P31/dark blue.mp3",
        },
        {
          url: "img/FriendsPlus/Page31/E1/8.jpg",
          audioUrl: "Audios/P31/black.mp3",
        },
        {
          url: "img/FriendsPlus/Page31/E1/9.jpg",
          audioUrl: "Audios/P31/white.mp3",
        },
        { url: "img/FriendsPlus/Page31/E1/10.jpg" },
      ],
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB3V2-U3-P31-E2",
    audio: "Audios/P31/audio-e2-p31.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page31/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page31/E2/2.jpg",
          audioUrl: "Audios/P31/tieude-e2-p31.mp3",
        },
        { url: "img/FriendsPlus/Page31/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page31/E2/4.jpg" }],
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB3V2-U3-P31-E3",
    audio: "",
    video: "",
    component: T6,
    maxLength: 6,
    inputSize: 150,
    padding: 0,
    exerciseKey: "img/FriendsPlus/Page31/E3/Key/answerKey.png",
    textAlign: "center",
    hideRulesRequired: true,
    isHiddenCheck: true,
    titleImage: "",
    questionImage: [],
    questions: [
      {
        title: `
        <div style='position: relative;'>
          <img src='img/FriendsPlus/Page31/E3/1.jpg' width='100%' />
          <div style='position: absolute; top: 100px; left: 729px;'>#</div>
          <div style='position: absolute; top: 147px; left: 221px;'>#</div>
          <div style='position: absolute; top: 147px; left: 729px;'>#</div>
        </div>
        <img src='img/FriendsPlus/Page31/E2/4.jpg' style='width:25cm; margin-left:50px'  />
        `,
        answer: ["yellow", "white", "black"],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB3V2-U3-P31-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [[{ url: "img/FriendsPlus/Page31/E4/1.jpg" }]],
  },
};

export default json;
