import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "Unit 3",
    id: "SB3V2-U3-P24-E1",
    audio: "Audios/P24/audio.e1.p24.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page24/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/2.jpg",
          audioUrl: "Audios/P24/tieude.e1.p24.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/3.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page24/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/5.jpg",
          audioUrl: "Audios/P24/aunt.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/7.jpg",
          audioUrl: "Audios/P24/uncle.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/9.jpg",
          audioUrl: "Audios/P24/cousin.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/10.jpg" },
      ],
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB3V2-U3-P24-E2",
    audio: "Audios/P24/audio.e2.p24.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page24/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page24/E2/2.jpg",
          audioUrl: "Audios/P24/tieude.e2.p24.mp3",
        },
        { url: "img/FriendsPlus/Page24/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page24/E2/4.jpg" }],
    ],
  },
};

export default json;
