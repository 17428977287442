import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P80-E1",
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "1",
        title: "Look at the picture. Where’s the museum?",
        color: "#213e8a",
      },
    ],
    exerciseKey: "img/FriendsPlus/Page80/E1/Key/answerKey.png",
    inputSize: 400,
    questionImage: [],
    questions: [
      {
        title: `
        
        <div> Where’s the museum?  </div>#
        <img src='img/FriendsPlus/Page80/E2/2.jpg'>
        `,
        answer: [
          "The museum is in Ha Noi. / It's in Ha Noi. / It is in Ha noi.",
        ],
      },
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-U80-E2",
    audio: "Audios/P80/audio-e2-p80.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page80/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page80/E2/b.jpg",
          audioUrl: "Audios/P80/tieude-e2-p80.mp3",
        },
        { url: "img/FriendsPlus/Page80/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page80/E2/2.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P80-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page80/E3/Key/answerKey.png",
    inputSize: 40,
    titleImage: "img/FriendsPlus/Page80/E3/1.jpg",
    questionImage: [],
    maxLength: 1,
    // hintBox: [
    //   {
    //     src: [
    //       "<s style='color: rgb(0 188 212)'>brushing my hair</s>",
    //       "washing the car",
    //       "making cakes",
    //       "taking photos",
    //     ],
    //     borderColor: "#468dcb",
    //     width: 600,
    //   },
    // ],
    questions: [
      {
        title: `
        <div style='display:flex ; margin-top:20px'>
          <div style='margin-right: 130px' ><b>1.</b>Where is the museum?</div>
          <div  style='margin-right:30px'><b>a.</b>Ha Noi</div>
          <div style='margin-right:30px'><b>b.</b>Ho Chi Minh City</div>
          <div>#</div>
        </div>
        <div style='display:flex ; margin-top:20px'>
          <div style='margin-right: 44px' ><b>2.</b>The gift shop is in the museum.</div>
          <div style='margin-right:65px'><b>a.</b>Yes</div>
          <div style='margin-right:180px'><b>b.</b>No</div>
          <div>#</div>
        </div>
        <div style='display:flex ; margin-top:20px'>
          <div style='margin-right: 53px' ><b>3.</b>The museum is open at 9 a.m.</div>
          <div style='margin-right:65px'><b>a.</b>Yes</div>
          <div style='margin-right:182px'><b>b.</b>No</div>
          <div>#</div>
        </div>
        <div style='display:flex ; margin-top:20px'>
          <div style='margin-right: 181px' ><b>4.</b>The elephant is... .</div>
          <div style='margin-right:60px'><b>a.</b>new</div>
          <div style='margin-right:180px'><b>b.</b>old</div>
          <div>#</div>
        </div>
        <div style='display:flex ; margin-top:20px'>
          <div style='margin-right: 147px' ><b>5.</b>The museum is great.</div>
          <div style='margin-right:65px'><b>a.</b>Yes</div>
          <div style='margin-right:182px'><b>b.</b>No</div>
          <div>#</div>
        </div>
        
        
        
        
        `,
        answer: ["a", "b", "b", "b", "a"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P80-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page70/E3/Key/answerKey.png",
    inputSize: 100,
    titleImage: "img/FriendsPlus/Page80/E4/1.jpg",
    questionImage: [],
    textareaStyle: { width: 700 },
    // hintBox: [
    //   {
    //     src: [
    //       "<s style='color: rgb(0 188 212)'>brushing my hair</s>",
    //       "washing the car",
    //       "making cakes",
    //       "taking photos",
    //     ],
    //     borderColor: "#468dcb",
    //     width: 600,
    //   },
    // ],
    questions: [
      {
        title: `
        
       
        <div style=''>
                    
            <textarea id="0" rows="8"></textarea>       
                   
        </div>
        
        
        `,
        answer: ["boat ", "goat ", "toy", "boy"],
      },
    ],
  },
};

export default json;
