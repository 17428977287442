import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "Unit 3",
    id: "SB3V2-U3-P27-E1",
    audio: "Audios/P27/audio-e1-p27.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page27/E1/Key/answerKey.png",
    component: D1,
    maxLength: 1,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page27/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page27/E1/b.jpg",
          audioUrl: "Audios/P27/tieude-e1-p27.mp3",
        },
        { url: "img/FriendsPlus/Page27/E1/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page27/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page27/E1/2.jpg" },
        { url: "img/FriendsPlus/Page27/E1/3.jpg", input: true, answer: "b" },
        { url: "img/FriendsPlus/Page27/E1/4.jpg" },
        { url: "img/FriendsPlus/Page27/E1/5.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page27/E1/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page27/E1/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page27/E1/8.jpg" },
        { url: "img/FriendsPlus/Page27/E1/9.jpg", input: true, answer: "b" },
        { url: "img/FriendsPlus/Page27/E1/10.jpg" },
        { url: "img/FriendsPlus/Page27/E1/11.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page27/E1/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page27/E1/13.jpg" }],
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB3V2-U3-P27-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [[{ url: "img/FriendsPlus/Page27/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 3",
    id: "SB3V2-U3-P27-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dash" },
    inputSize: 100,
    maxLength: 53,
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page27/E3/1.jpg' />
        <div style='border: 2px solid black; margin: 50px 200px;padding: 5px;border-radius: 20px; text-align: center; width: 12cm'>
          its&emsp;&emsp;my&emsp;&emsp;my&emsp;&emsp;their&emsp;&emsp;our
        </div> 
        <div style='line-height:50px;margin-left:40px'>  
          <div>This is # aunt and this is # uncle.</div>     
          <div>This is # house.</div>     
          <div>There is a number on # door.</div>     
          <div># family is happy.</div>     
        </div> 
        `,
        answer: [],
      },
    ],
  },
};

export default json;
