import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P57-E1",
    audio: "Audios/P58/audio-e1-p57.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        // Column1
        { url: "img/FriendsPlus/Page57/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page57/E1/b.jpg",
          audioUrl: "Audios/P58/tieude-e1-p57.mp3",
        },
        { url: "img/FriendsPlus/Page57/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page57/E1/1.jpg",
          audioUrl: "Audios/P58/sh.mp3",
        },
        {
          url: "img/FriendsPlus/Page57/E1/2.jpg",
          audioUrl: "Audios/P58/shoes.mp3",
        },
        {
          url: "img/FriendsPlus/Page57/E1/3.jpg",
          audioUrl: "Audios/P58/ch.mp3",
        },
        {
          url: "img/FriendsPlus/Page57/E1/4.jpg",
          audioUrl: "Audios/P58/chair.mp3",
        },
        {
          url: "img/FriendsPlus/Page57/E1/5.jpg",
          audioUrl: "Audios/P58/th.mp3",
        },
        {
          url: "img/FriendsPlus/Page57/E1/6.jpg",
          audioUrl: "Audios/P58/three.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P57-E2",
    audio: "Audios/P58/audio-e2-p57.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        // Column1
        { url: "img/FriendsPlus/Page57/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page57/E2/2.jpg",
          audioUrl: "Audios/P58/tieude-e2-p57.mp3",
        },
        { url: "img/FriendsPlus/Page57/E2/3.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page57/E2/4.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P57-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page57/E3/1.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page57/E2/2.jpg" },
      ],
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P57-E4",
    audio: "",
    video: "",
    component: D1,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page57/E4/Key/answerKey.png",
    fontSize: 29,
    maxLength: 5,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page57/E4/10.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page57/E4/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page57/E4/2.jpg" },
        {
          url: "img/FriendsPlus/Page57/E4/3.jpg",
          input: true,
          answer: "thin",
        },
      ],
      [{ url: "img/FriendsPlus/Page57/E4/4.jpg" }],
      // Column3
      [
        { url: "img/FriendsPlus/Page57/E4/5.jpg" },
        {
          url: "img/FriendsPlus/Page57/E4/6.jpg",
          input: true,
          answer: "chair",
        },
        { url: "img/FriendsPlus/Page57/E4/7.jpg" },
        {
          url: "img/FriendsPlus/Page57/E4/8.jpg",
          input: true,
          answer: "shirt",
        },
      ],

      [
        // Column5
        { url: "img/FriendsPlus/Page57/E4/9.jpg" },
      ],
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P57-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        // Column1
        { url: "img/FriendsPlus/Page57/E5/1.jpg" },
      ],
    ],
  },
};

export default json;
