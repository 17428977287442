import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB3V2-U4-P36-E1",
    audio: "",
    video: "",
    component: UI,
    exerciseKey: "",
    recorder: true,
    questionImage: [
      [{ url: "img/FriendsPlus/Page36/E1/1.jpg" }],
      [{ url: "img/FriendsPlus/Page36/E2/4.jpg" }],
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB3V2-U4-P36-E2",
    audio: "Audios/P36/audio-e2-p36.mp3",
    video: "",
    component: UI,
    exerciseKey: "",
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page36/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page36/E2/2.jpg",
          audioUrl: "Audios/P36/tieude-e2-p36.mp3",
        },
        { url: "img/FriendsPlus/Page36/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page36/E2/4.jpg" }],
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB3V2-U4-P36-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "dash" },
    exerciseKey: "img/FriendsPlus/Page36/E3/Key/answerKey.png",
    maxLength: 1,
    inputSize: 50,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page26/E3/1.jpg' />
        <img src='img/FriendsPlus/Page36/E2/2.jpg' height='600'  />
        <div style='display: flex; margin-top: 10px '>
          <div style='margin:0px 0px 0px 50px'><b>1.</b> The elephant is big.</div>#
          <div style='margin-left: 198px'><b>3.</b> The elephant is black and white.</div>#
        </div>
        <div style='display: flex '>
          <div style='margin:0px 0px 0px 50px '><b>2.</b> The elephant has two hands.</div>#
          <div style='margin:0px 0px 0px 100px;'><b>4.</b> The elephant has a long nose.</div>#
        </div>
        
        `,
        answer: ["T", "F", "F", "T"],
      },
    ],
  },
};

export default json;
