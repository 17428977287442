import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P68-E1",
    audio: "Audios/P68/audio-e1-p68.mp3",
    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page68/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page68/E1/b.jpg",
          audioUrl: "Audios/P68/tieude-e1-p68.mp3",
        },
        { url: "img/FriendsPlus/Page68/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page68/E1/1.jpg",
          audioUrl: "Audios/P68/jump.mp3",
        },
        { url: "img/FriendsPlus/Page68/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page68/E1/3.jpg",
          audioUrl: "Audios/P68/read.mp3",
        },
        { url: "img/FriendsPlus/Page68/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page68/E1/5.jpg",
          audioUrl: "Audios/P68/write.mp3",
        },
      ],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P68-E2",
    audio: "Audios/P68/audio-e2-p68.mp3",
    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page68/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page68/E2/b.jpg",
          audioUrl: "Audios/P68/tieude-e2-p68.mp3",
        },
        { url: "img/FriendsPlus/Page68/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page68/E3/2.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P68-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page68/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page68/E3/2.jpg" }],
    ],
  },
};

export default json;
