import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P91-E1",
    audio: "Audios/P91/audio-e1-p91.mp3",
    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page91/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page91/E1/b.jpg",
          audioUrl: "Audios/P91/tieude-e1-p91.mp3",
        },
        { url: "img/FriendsPlus/Page91/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page91/E1/1.jpg",
          audioUrl: "Audios/P91/oor.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/2.jpg",
          audioUrl: "Audios/P91/poor.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/3.jpg",
          audioUrl: "Audios/P91/tour.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/4.jpg",
          audioUrl: "Audios/P91/ou.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/5.jpg",
          audioUrl: "Audios/P91/mouse.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/6.jpg",
          audioUrl: "Audios/P91/house.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page91/E1/7.jpg",
          audioUrl: "Audios/P91/our.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/8.jpg",
          audioUrl: "Audios/P91/poor.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/9.jpg",
          audioUrl: "Audios/P91/tour.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page91/E1/11.jpg",
          audioUrl: "Audios/P91/mouse.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/12.jpg",
          audioUrl: "Audios/P91/house.mp3",
        },
      ],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P91-E2",
    audio: "Audios/P91/audio-e2-p91.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page91/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page91/E2/b.jpg",
          audioUrl: "Audios/P91/tieude-e2-p91.mp3",
        },
        { url: "img/FriendsPlus/Page91/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page91/E2/2.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P91-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page91/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page91/E2/2.jpg" }],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P91-E4",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page91/E4/Key/answerKey.png",
    inputSize: 100,
    titleImage: "img/FriendsPlus/Page91/E4/5.jpg",
    questionImage: [],
    maxLength: 5,
    // hintBox: [
    //   {
    //     src: [
    //       "<s style='color: rgb(0 188 212)'>brushing my hair</s>",
    //       "washing the car",
    //       "making cakes",
    //       "taking photos",
    //     ],
    //     borderColor: "#468dcb",
    //     width: 600,
    //   },
    // ],
    questions: [
      {
        title: `
        
        <div style='display:flex; margin: 0px 0px 10px 50px'>
            <div style='margin-right:50px'>
              <img src='img/FriendsPlus/Page91/E4/1.jpg'>
              <div style='margin-left:130px'>#</div>
            </div>        
            <div>
              <img src='img/FriendsPlus/Page91/E4/2.jpg'>
              <div style='margin-left:130px'>#</div>
            </div>        
                   
        </div>
        <div style='display:flex; margin: 0px 0px 10px 50px'>
            <div style='margin-right:50px'>
              <img src='img/FriendsPlus/Page91/E4/3.jpg'>
              <div style='margin-left:130px'>#</div>
            </div>        
            <div>
              <img src='img/FriendsPlus/Page91/E4/4.jpg'>
              <div style='margin-left:130px'>#</div>
            </div>        
                   
        </div>
        
        
        `,
        answer: ["poor", "Mouse", "house", "tour"],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P91-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page91/E5/1.jpg" }],
    ],
  },
};

export default json;
