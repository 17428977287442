import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    unit: "Stater",
    id: "SB3V2-Stater-P6-E1",
    audio: "Audios/P6/audio.e1.p6.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page6/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page6/E1/2.jpg",
          audioUrl: "Audios/P6/tieude.e1.p6.mp3",
        },
        { url: "img/FriendsPlus/Page6/E1/3.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page6/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/2.jpg",
          audioUrl: "Audios/P6/3.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/3.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/4.jpg",
          audioUrl: "Audios/P6/6.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/6.jpg",
          audioUrl: "Audios/P6/8.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/7.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/8.jpg",
          audioUrl: "Audios/P6/10.mp3",
        },
      ],
      [
        { url: "img/FriendsPlus/Page6/E2/9.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/10.jpg",
          audioUrl: "Audios/P6/2.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/11.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/12.jpg",
          audioUrl: "Audios/P6/5.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/13.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E2/14.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E2/15.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/16.jpg",
          audioUrl: "Audios/P6/9.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/17.jpg" },
      ],

      [
        {
          url: "img/FriendsPlus/Page6/E2/18.jpg",
          audioUrl: "Audios/P6/1.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/19.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E2/20.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E2/21.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/22.jpg",
          audioUrl: "Audios/P6/4.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/23.jpg" },
      ],

      [
        { url: "img/FriendsPlus/Page6/E2/24.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/25.jpg",
          audioUrl: "Audios/P6/7.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/26.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E2/27.jpg" }],
    ],
  },
  2: {
    unit: "Stater",
    id: "SB3V2-Stater-P6-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page6/E2/28.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/29.jpg",
          audioUrl: "Audios/P6/tieude.e2.p6.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/30.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page6/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/2.jpg",
          audioUrl: "Audios/P6/3.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/3.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/4.jpg",
          audioUrl: "Audios/P6/6.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/6.jpg",
          audioUrl: "Audios/P6/8.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/7.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/8.jpg",
          audioUrl: "Audios/P6/10.mp3",
        },
      ],
      [
        { url: "img/FriendsPlus/Page6/E2/9.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/10.jpg",
          audioUrl: "Audios/P6/2.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/11.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/12.jpg",
          audioUrl: "Audios/P6/5.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/13.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E2/14.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E2/15.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/16.jpg",
          audioUrl: "Audios/P6/9.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/17.jpg" },
      ],

      [
        {
          url: "img/FriendsPlus/Page6/E2/18.jpg",
          audioUrl: "Audios/P6/1.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/19.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E2/20.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E2/21.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/22.jpg",
          audioUrl: "Audios/P6/4.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/23.jpg" },
      ],

      [
        { url: "img/FriendsPlus/Page6/E2/24.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/25.jpg",
          audioUrl: "Audios/P6/7.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/26.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E2/27.jpg" }],
    ],
  },
  3: {
    unit: "Stater",
    id: "SB3V2-Stater-P6-E3",
    audio: "Audios/P6/P6-E3.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page6/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page6/E2/2.jpg" }],

      [{ url: "img/FriendsPlus/Page6/E3/2.jpg" }],
    ],
  },
  4: {
    unit: "Stater",
    id: "SB3V2-Stater-P4-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [{ url: "img/FriendsPlus/Page6/E4/1.jpg" }],
      [{ url: "img/FriendsPlus/Page6/E4/2.jpg" }],
    ],
  },
};

export default json;
