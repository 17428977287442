import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 5",
    id: "SB3V2-U5-P43-E1",
    audio: "Audios/P43/audio-e1-p43.mp3",
    video: "",
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page43/E1/Key/answerKey.png",
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page43/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page43/E1/2.jpg",
          audioUrl: "Audios/P43/tieude-e1-p43.mp3",
        },
        { url: "img/FriendsPlus/Page43/E1/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page43/E1/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page43/E1/5.jpg" },
        { url: "img/FriendsPlus/Page43/E1/6.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page43/E1/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page43/E1/8.jpg" },
        { url: "img/FriendsPlus/Page43/E1/9.jpg", input: 1 },
        { url: "img/FriendsPlus/Page43/E1/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page43/E1/11.jpg" }],
      [
        { url: "img/FriendsPlus/Page43/E1/12.jpg" },
        { url: "img/FriendsPlus/Page43/E1/13.jpg", input: 2 },
        { url: "img/FriendsPlus/Page43/E1/14.jpg" },
        { url: "img/FriendsPlus/Page43/E1/15.jpg", input: 3 },
        { url: "img/FriendsPlus/Page43/E1/16.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page43/E1/17.jpg" },
        { url: "img/FriendsPlus/Page43/E1/18.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page43/E1/19.jpg" },
        { url: "img/FriendsPlus/Page43/E1/20.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page43/E1/21.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page43/E1/22.jpg" }],
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB3V2-U5-P43-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [{ url: "img/FriendsPlus/Page43/E2/1.jpg" }],
      [{ url: "img/FriendsPlus/Page42/E2/4.jpg" }],
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB3V2-U5-P43-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [{ url: "img/FriendsPlus/Page43/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page43/E3/2.jpg" }],
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB3V2-U5-P43-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    // hideBtnFooter: true,
    stylesTextInput: { borderBottom: "dash" },
    maxLength: 50,
    inputSize: 400,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page43/E4/1.jpg' />
        
        <div style='margin:30px 0px 30px 50px'>
         Hello. My name’s#. I like#.<br>and# , but #.
        
        </div>
        
        
        <img src='img/FriendsPlus/Page43/E4/2.jpg' />
        `,
        answer: ["How often", "Who", "Where", "When", "What", "Why"],
      },
    ],
  },
};

export default json;
