import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Fluency Time! 3",
    id: "SB3V2-FT3-P75-E1",
    audio: "Audios/P75/audio-e1-p75.mp3",
    video: "",
    component: UI,
    isAllowSubmit: true,
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page75/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page75/E1/b.jpg",
          audioUrl: "Audios/P75/tieude-e1-p75.mp3",
        },
        { url: "img/FriendsPlus/Page75/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page75/E1/1.jpg",
          audioUrl: "Audios/P75/see.mp3",
        },
        {
          url: "img/FriendsPlus/Page75/E1/2.jpg",
          audioUrl: "Audios/P75/hear.mp3",
        },
        {
          url: "img/FriendsPlus/Page75/E1/3.jpg",
          audioUrl: "Audios/P75/smell.mp3",
        },
        {
          url: "img/FriendsPlus/Page75/E1/4.jpg",
          audioUrl: "Audios/P75/taste.mp3",
        },
        {
          url: "img/FriendsPlus/Page75/E1/5.jpg",
          audioUrl: "Audios/P75/touch.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Fluency Time! 3",
    id: "SB3V2-FT3-P75-E2",
    audio: "Audios/P75/audio-e2-p75.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page75/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page75/E2/b.jpg",
          audioUrl: "Audios/P75/tieude-e2-p75.mp3",
        },
        { url: "img/FriendsPlus/Page75/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page75/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page75/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page75/E2/3.jpg",
          audioUrl: "Audios/P75/you can see with your eyes.mp3",
        },
        { url: "img/FriendsPlus/Page75/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page75/E2/5.jpg",
          audioUrl: "Audios/P75/you can hear with your ears.mp3",
        },
        { url: "img/FriendsPlus/Page75/E2/6.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page75/E2/7.jpg" },
        {
          url: "img/FriendsPlus/Page75/E2/8.jpg",
          audioUrl: "Audios/P75/you can see with your eyes.mp3",
        },
        { url: "img/FriendsPlus/Page75/E2/9.jpg" },
        {
          url: "img/FriendsPlus/Page75/E2/10.jpg",
          audioUrl: "Audios/P75/you can hear with your ears.mp3",
        },
        { url: "img/FriendsPlus/Page75/E2/11.jpg" },
        {
          url: "img/FriendsPlus/Page75/E2/12.jpg",
          audioUrl: "Audios/P75/you can smell with your nose.mp3",
        },
        { url: "img/FriendsPlus/Page75/E2/13.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page75/E2/14.jpg" },
        {
          url: "img/FriendsPlus/Page75/E2/15.jpg",
          audioUrl: "Audios/P75/you can see with your eyes.mp3",
        },
        { url: "img/FriendsPlus/Page75/E2/16.jpg" },
        {
          url: "img/FriendsPlus/Page75/E2/17.jpg",
          audioUrl: "Audios/P75/you can hear with your ears.mp3",
        },
        { url: "img/FriendsPlus/Page75/E2/18.jpg" },
        {
          url: "img/FriendsPlus/Page75/E2/19.jpg",
          audioUrl: "Audios/P75/you can smell with your nose.mp3",
        },
        { url: "img/FriendsPlus/Page75/E2/20.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page75/E2/21.jpg" },
        {
          url: "img/FriendsPlus/Page75/E2/22.jpg",
          audioUrl: "Audios/P75/you can taste with you tongue.mp3",
        },
        { url: "img/FriendsPlus/Page75/E2/23.jpg" },
        {
          url: "img/FriendsPlus/Page75/E2/24.jpg",
          audioUrl: "Audios/P75/you can touch with your hands.mp3",
        },
        { url: "img/FriendsPlus/Page75/E2/25.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page75/E2/26.jpg" },
        {
          url: "img/FriendsPlus/Page75/E2/27.jpg",
          audioUrl: "Audios/P75/you can taste with you tongue.mp3",
        },
        { url: "img/FriendsPlus/Page75/E2/28.jpg" },
        {
          url: "img/FriendsPlus/Page75/E2/29.jpg",
          audioUrl: "Audios/P75/you can touch with your hands.mp3",
        },
        { url: "img/FriendsPlus/Page75/E2/30.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page75/E2/31.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Fluency Time! 3  ",
    id: "SB3V2-FT3-P75-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page75/E3/Key/answerKey.png",
    inputSize: 150,
    titleImage: "img/FriendsPlus/Page75/E3/1.jpg",
    questionImage: [],
    textareaStyle: { width: 800 },
    questions: [
      {
        title: `
          <div><textarea id="0" rows="5"></textarea></div>
        `,
        answer: ["How many", "How much"],
      },
    ],
  },
};

export default json;
