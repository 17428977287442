import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  4: {
    unit: "Unit 3",
    id: "SB3V2-U3-P29-E4",
    audio: "Audios/P29/audio-e4-p29.mp3",

    video: "",
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: "img/FriendsPlus/Page29/E1/Key/answerKey.png",
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page29/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page29/E1/2.jpg",
          audioUrl: "Audios/P29/tieude-e4-29.mp3",
        },
        { url: "img/FriendsPlus/Page29/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page29/E1/4.jpg",
          audioUrl: "Audios/P29/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page29/E1/5.jpg",
          audioUrl: "Audios/P29/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page29/E1/6.jpg",
          audioUrl: "Audios/P29/hinh5.mp3",
        },
      ],
      [
        { url: "img/FriendsPlus/Page29/E1/7.jpg" },
        { url: "img/FriendsPlus/Page29/E1/8.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page29/E1/9.jpg" },
        { url: "img/FriendsPlus/Page29/E1/10.jpg", input: 2 },
        { url: "img/FriendsPlus/Page29/E1/11.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page29/E1/12.jpg" },
        { url: "img/FriendsPlus/Page29/E1/13.jpg", input: 1 },
        { url: "img/FriendsPlus/Page29/E1/14.jpg" },
        { url: "img/FriendsPlus/Page29/E1/15.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page29/E1/16.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page29/E1/17.jpg",
          audioUrl: "Audios/P29/hinh.mp3",
        },
        {
          url: "img/FriendsPlus/Page29/E1/18.jpg",
          audioUrl: "Audios/P29/hinh4.mp3",
        },
        {
          url: "img/FriendsPlus/Page29/E1/19.jpg",
          audioUrl: "Audios/P29/hinh6.mp3",
        },
      ],
      [
        { url: "img/FriendsPlus/Page29/E1/20.jpg" },
        { url: "img/FriendsPlus/Page29/E1/21.jpg", input: 3 },
        { url: "img/FriendsPlus/Page29/E1/22.jpg" },
        { url: "img/FriendsPlus/Page29/E1/23.jpg", input: 4 },
        { url: "img/FriendsPlus/Page29/E1/24.jpg" },
        { url: "img/FriendsPlus/Page29/E1/25.jpg", input: 5 },
        { url: "img/FriendsPlus/Page29/E1/26.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page29/E1/27.jpg" },
        { url: "img/FriendsPlus/Page29/E1/28.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page29/E1/29.jpg" },
        { url: "img/FriendsPlus/Page29/E1/30.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page29/E1/31.jpg" },
        { url: "img/FriendsPlus/Page29/E1/32.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page29/E1/33.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page29/E1/34.jpg" }],
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB3V2-U3-P29-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [[{ url: "img/FriendsPlus/Page29/E2/1.jpg" }]],
  },
};

export default json;
