import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P93-E1",
    audio: "Audios/P93/audio-e1-p93.mp3",
    video: "",
    isHiddenCheck: true,
    maxLength: 1,
    component: D1,
    exerciseKey: "img/FriendsPlus/Page93/E1/Key/answerKey.png",

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page93/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page93/E1/b.jpg",
          audioUrl: "Audios/P93/tieude-e1-p93.mp3",
        },
        { url: "img/FriendsPlus/Page93/E1/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page93/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page93/E1/2.jpg" },
        { url: "img/FriendsPlus/Page93/E1/3.jpg", input: true, answer: "7" },
        { url: "img/FriendsPlus/Page93/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page93/E1/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page93/E1/6.jpg" },
        { url: "img/FriendsPlus/Page93/E1/7.jpg", input: true, answer: "5" },
        { url: "img/FriendsPlus/Page93/E1/8.jpg" },
        { url: "img/FriendsPlus/Page93/E1/9.jpg", input: true, answer: "4" },
        { url: "img/FriendsPlus/Page93/E1/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page93/E1/11.jpg" }],
      [
        { url: "img/FriendsPlus/Page93/E1/12.jpg" },
        { url: "img/FriendsPlus/Page93/E1/13.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page93/E1/14.jpg" },
        { url: "img/FriendsPlus/Page93/E1/15.jpg", input: true, answer: "6" },
        { url: "img/FriendsPlus/Page93/E1/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page93/E1/17.jpg" }],
      [
        { url: "img/FriendsPlus/Page93/E1/18.jpg" },
        { url: "img/FriendsPlus/Page93/E1/19.jpg", input: true, answer: "3" },
        { url: "img/FriendsPlus/Page93/E1/20.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page93/E1/21.jpg" }],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P93-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page93/E2/1.jpg" }],
      [{ url: "img/FriendsPlus/Page93/E2/2.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P93-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page93/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page93/E3/2.jpg" }],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P93-E2",
    audio: "",
    video: "",
    fontSize: 30,
    inputHeight: 35,
    component: D1,
    hideBtnFooter: true,

    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page93/E4/18.jpg" }],
      [
        { url: "img/FriendsPlus/Page93/E4/1.jpg" },
        { url: "img/FriendsPlus/Page93/E4/2.jpg", input: true, answer: "" },
      ],
      [{ url: "img/FriendsPlus/Page93/E4/3.jpg" }],
      [
        { url: "img/FriendsPlus/Page93/E4/4.jpg" },
        { url: "img/FriendsPlus/Page93/E4/5.jpg", input: true, answer: "" },
      ],
      [{ url: "img/FriendsPlus/Page93/E4/6.jpg" }],
      [
        { url: "img/FriendsPlus/Page93/E4/7.jpg" },
        { url: "img/FriendsPlus/Page93/E4/8.jpg", input: true, answer: "" },
      ],
      [{ url: "img/FriendsPlus/Page93/E4/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page93/E4/10.jpg" },
        { url: "img/FriendsPlus/Page93/E4/11.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page93/E4/12.jpg" },
        { url: "img/FriendsPlus/Page93/E4/13.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page93/E4/14.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page93/E4/15.jpg" }],
      [
        { url: "img/FriendsPlus/Page93/E4/16.jpg" },
        { url: "img/FriendsPlus/Page93/E4/17.jpg", input: true, answer: "" },
      ],
    ],
  },
};

export default json;
