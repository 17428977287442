import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Fluency Timel 2",
    id: "SB3V2-FT2-P53-E1",
    audio: "Audios/P53/audio-e1-p52.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page53/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page53/E1/b.jpg",
          audioUrl: "Audios/P53/tieude-e1-p52.mp3",
        },
        { url: "img/FriendsPlus/Page53/E1/c.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page53/E1/1.jpg",audioUrl:"Audios/P53/insect.mp3" },
        { url: "img/FriendsPlus/Page53/E1/2.jpg" },
        { url: "img/FriendsPlus/Page53/E1/3.jpg",audioUrl:"Audios/P53/feather.mp3" },
        { url: "img/FriendsPlus/Page53/E1/4.jpg" },
        { url: "img/FriendsPlus/Page53/E1/5.jpg",audioUrl:"Audios/P53/wing.mp3" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Fluency Timel 2",
    id: "SB3V2-FT2-P53-E2",
    audio: "Audios/P53/audio-e2-p52.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page53/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page53/E2/2.jpg",
          audioUrl: "Audios/P53/tieude-e2-p52.mp3",
        },
        { url: "img/FriendsPlus/Page53/E2/3.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page53/E1/1.jpg",audioUrl:"Audios/P53/insect.mp3" },
        { url: "img/FriendsPlus/Page53/E1/2.jpg" },
        { url: "img/FriendsPlus/Page53/E1/3.jpg",audioUrl:"Audios/P53/feather.mp3" },
        { url: "img/FriendsPlus/Page53/E1/4.jpg" },
        { url: "img/FriendsPlus/Page53/E1/5.jpg",audioUrl:"Audios/P53/wing.mp3" },
      ],
    ],
  },

  3: {
    // Exercise num
    unit: "Fluency Timel 2",
    id: "SB3V2-FT2-P53-E3",
    audio: "",
    video: "",
    component: D1,
    maxLength: 1,
    fontSize: 30,
    exerciseKey: "img/FriendsPlus/Page53/E3/Key/answerKey.png",
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page53/E3/16.jpg" }],
      [{ url: "img/FriendsPlus/Page53/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page53/E3/2.jpg" },
        { url: "img/FriendsPlus/Page53/E3/3.jpg", input: true, answer: "6" },
        { url: "img/FriendsPlus/Page53/E3/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page53/E3/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page53/E3/6.jpg" },
        { url: "img/FriendsPlus/Page53/E3/7.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page53/E3/8.jpg" },
        { url: "img/FriendsPlus/Page53/E3/9.jpg", input: true, answer: "2 " },
        { url: "img/FriendsPlus/Page53/E3/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page53/E3/11.jpg" }],
      [
        { url: "img/FriendsPlus/Page53/E3/12.jpg" },
        { url: "img/FriendsPlus/Page53/E3/13.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page53/E3/14.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page53/E3/15.jpg" }],
    ],
  },
  4: {
    unit: "Fluency Timel 2",
    id: "SB3V2-FT2-P53-E4",
    audio: "",
    video: "",
    component: T6,
    textareaStyle: { width: "70%" },
    hideBtnFooter: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page53/E4/1.jpg' />
          <div style='margin-left:50px;'><textarea id="0" rows="5"></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
