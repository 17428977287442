import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P78-E1",
    audio: "Audios/P78/audio-e1-p78.mp3",
    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page78/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page78/E1/b.jpg",
          audioUrl: "Audios/P78/tieude-e1-p78.mp3",
        },
        { url: "img/FriendsPlus/Page78/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page78/E1/1.jpg",
          audioUrl: "Audios/P78/old.mp3",
        },
        { url: "img/FriendsPlus/Page78/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page78/E1/3.jpg",
          audioUrl: "Audios/P78/new.mp3",
        },
        { url: "img/FriendsPlus/Page78/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page78/E1/5.jpg",
          audioUrl: "Audios/P78/great.mp3",
        },
      ],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P78-E2",
    audio: "Audios/P78/audio-e2-p78.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page78/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page78/E2/b.jpg",
          audioUrl: "Audios/P78/tieude-e2-p78.mp3",
        },
        { url: "img/FriendsPlus/Page78/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page78/E3/2.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P78-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page78/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page78/E3/2.jpg" }],
    ],
  },
};
export default json;
