import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "Stater",
    id: "SB3V2-Stater-P9-E1",
    audio: "Audios/P9/audio.e1.p9.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page9/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page9/E1/7.jpg",
          audioUrl: "Audios/P9/tieude.e1.p9.mp3",
        },
        { url: "img/FriendsPlus/Page9/E1/8.jpg" },
      ],

      [
        {
          url: "img/FriendsPlus/Page9/E1/1.jpg",
          audioUrl: "Audios/P9/Sit down.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E1/2.jpg",
          audioUrl: "Audios/P9/Stand up.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E1/3.jpg",
          audioUrl: "Audios/P9/Open your book.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E1/4.jpg",
          audioUrl: "Audios/P9/close your book.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E1/5.jpg",
          audioUrl: "Audios/P9/Don't talk.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Stater",
    id: "SB3V2-Stater-P9-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page9/E2/1.jpg" }],
      [{ url: "img/FriendsPlus/Page9/E2/2.jpg" }],
    ],
  },
  3: {
    unit: "Stater",
    id: "SB3V2-Stater-P9-E3",
    audio: "Audios/P9/audio.e3.p9.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page9/E3/a.jpg" },
        {
          url: "img/FriendsPlus/Page9/E3/b.jpg",
          audioUrl: "Audios/P9/tieude.e3.p9.mp3",
        },
        { url: "img/FriendsPlus/Page9/E3/c.jpg" },
      ],

      [
        {
          url: "img/FriendsPlus/Page9/E3/1.jpg",
          audioUrl: "Audios/P9/hinh1.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E3/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E3/3.jpg",
          audioUrl: "Audios/P9/hinh2.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E3/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E3/5.jpg",
          audioUrl: "Audios/P9/hinh3.e3.mp3",
        },
      ],
      [{ url: "img/FriendsPlus/Page9/E3/6.jpg" }],

      [
        {
          url: "img/FriendsPlus/Page9/E3/7.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E3/8.jpg",
          audioUrl: "Audios/P9/hinh5.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E3/9.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E3/10.jpg",
          audioUrl: "Audios/P9/hinh4.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E3/11.jpg",
        },
      ],
    ],
  },
};

export default json;
