import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 1",
    id: "SB3V2-U1-P14-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page14/E1/1.jpg" }],
      [{ url: "img/FriendsPlus/Page14/E2/2.jpg" }],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB3V2-U1-P14-E2",
    audio: "Audios/P14/audio.e2.p14.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page14/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page14/E2/2.jpg",
          audioUrl: "Audios/P14/tieude.e2.p14.mp3",
        },
        { url: "img/FriendsPlus/Page14/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E2/4.jpg" }],
    ],
  },

  3: {
    unit: "Unit 1",
    id: "SB3V2-U1-P14-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page14/E3/Key/answerKey.png",
    component: D1,
    maxLength: 8,
    questionImage: [
      [{ url: "img/FriendsPlus/Page14/E3/10.jpg" }],
      [{ url: "img/FriendsPlus/Page14/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page14/E3/3.jpg",
          input: true,
          answer: "a ball",
        },
        { url: "img/FriendsPlus/Page14/E3/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E3/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E3/6.jpg" },
        { url: "img/FriendsPlus/Page14/E3/7.jpg", input: true, answer: "blue" },
        { url: "img/FriendsPlus/Page14/E3/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E3/9.jpg" }],
      [{ url: "img/FriendsPlus/Page14/E2/2.jpg" }],
    ],
  },
};

export default json;
