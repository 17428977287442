import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";

const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P67-E1",
    audio: "Audios/P66/audio-e3-p66.mp3",
    video: "/img/FriendsPlus/Page66/Video/Unit09.mp4",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page67/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page67/E1/b.jpg",
          audioUrl: "Audios/P66/tieude-e3-p66.mp3",
        },
        { url: "img/FriendsPlus/Page67/E1/c.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page66/E3/1.jpg",
          audioUrl: "Audios/P66/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E3/2.jpg",
          audioUrl: "Audios/P66/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page66/E3/3.jpg",
          audioUrl: "Audios/P66/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E3/4.jpg",
          audioUrl: "Audios/P66/hinh4.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P67-E2",
    audio: "Audios/P67/audio-e2-p67.mp3",
    video: "",
    component: D1,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page67/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page67/E2/b.jpg",
          audioUrl: "Audios/P67/audio-e2-p67.mp3",
        },
        { url: "img/FriendsPlus/Page67/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page67/E2/1.jpg" }],

      [
        {
          url: "img/FriendsPlus/Page67/E2/2.jpg",
          audioUrl: "Audios/P67/i can run.mp3",
        },
        { url: "img/FriendsPlus/Page67/E2/3.jpg" },
        {
          url: "img/FriendsPlus/Page67/E2/4.jpg",
          audioUrl: "Audios/P67/i can't catch.mp3",
        },
        {
          url: "img/FriendsPlus/Page67/E2/5.jpg",
          audioUrl: "Audios/P67/she can play soccer.mp3",
        },
        {
          url: "img/FriendsPlus/Page67/E2/6.jpg",
          audioUrl: "Audios/P67/it can fly.mp3",
        },
      ],
      [{ url: "img/FriendsPlus/Page67/E2/7.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P67-E2",
    audio: "Audios/P67/audio-e2-p67.mp3",
    video: "",
    component: D1,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page67/E3/a.jpg" },
        {
          url: "img/FriendsPlus/Page67/E3/b.jpg",
          audioUrl: "Audios/P67/tieude-e3-p67.mp3",
        },
        { url: "img/FriendsPlus/Page67/E3/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page67/E3/2.jpg" }],
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P67-E2",
    audio: "img/FriendsPlus/Page58/Audio/audio-e2-p58.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page67/E4/Key/answerKey.png",
    component: D1,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page67/E4/1.jpg" }],
      [{ url: "img/FriendsPlus/Page67/E4/2.jpg" }],
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 9",
    id: "SB5-U9-P67-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page67/E5/1.jpg" },
      ],
    ],
  },
};

export default json;
