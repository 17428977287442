import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P84-E1",
    audio: "Audios/P84/audio-e1-p84.mp3",
    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page84/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page84/E1/b.jpg",
          audioUrl: "Audios/P84/tieude-e1-p84.mp3",
        },
        { url: "img/FriendsPlus/Page84/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page84/E1/1.jpg",
          audioUrl: "Audios/P84/11.mp3",
        },
        { url: "img/FriendsPlus/Page84/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page84/E1/3.jpg",
          audioUrl: "Audios/P84/12.mp3",
        },
        { url: "img/FriendsPlus/Page84/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page84/E1/5.jpg",
          audioUrl: "Audios/P84/13.mp3",
        },
        { url: "img/FriendsPlus/Page84/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page84/E1/7.jpg",
          audioUrl: "Audios/P84/14.mp3",
        },
        { url: "img/FriendsPlus/Page84/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page84/E1/9.jpg",
          audioUrl: "Audios/P84/15.mp3",
        },
      ],
      [{ url: "img/FriendsPlus/Page84/E1/10.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page84/E1/11.jpg",
          audioUrl: "Audios/P84/16.mp3",
        },
        { url: "img/FriendsPlus/Page84/E1/12.jpg" },
        {
          url: "img/FriendsPlus/Page84/E1/13.jpg",
          audioUrl: "Audios/P84/17.mp3",
        },
        { url: "img/FriendsPlus/Page84/E1/14.jpg" },
        {
          url: "img/FriendsPlus/Page84/E1/15.jpg",
          audioUrl: "Audios/P84/18.mp3",
        },
        { url: "img/FriendsPlus/Page84/E1/16.jpg" },
        {
          url: "img/FriendsPlus/Page84/E1/17.jpg",
          audioUrl: "Audios/P84/19.mp3",
        },
        { url: "img/FriendsPlus/Page84/E1/18.jpg" },
        {
          url: "img/FriendsPlus/Page84/E1/19.jpg",
          audioUrl: "Audios/P84/20.mp3",
        },
      ],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P84-E2",
    audio: "Audios/P84/audio-e2-p84.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page84/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page84/E2/b.jpg",
          audioUrl: "Audios/P84/tieude-e2-p84.mp3",
        },
        { url: "img/FriendsPlus/Page84/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page84/E3/2.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P84-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page84/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page84/E3/2.jpg" }],
    ],
  },
};

export default json;
