import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";

const json = {
  1: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P89-E1",
    audio: "Audios/P88/audio-e3-p88.mp3",
    video: "Video/Uni/audio-e3-p88.mp4",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page89/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page89/E1/b.jpg",
          audioUrl: "Audios/P88/tieude-e3-p88.mp3",
        },
        { url: "img/FriendsPlus/Page89/E1/c.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page88/E3/1.jpg",
          audioUrl: "Audios/P88/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E3/2.jpg",
          audioUrl: "Audios/P88/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page88/E3/3.jpg",
          audioUrl: "Audios/P88/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E3/4.jpg",
          audioUrl: "Audios/P88/hinh4.mp3",
        },
      ],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P89-E2",
    audio: "Audios/P89/audio-e2-p89.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page89/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page89/E2/b.jpg",
          audioUrl: "Audios/P89/tieude-e2-p89.mp3",
        },
        { url: "img/FriendsPlus/Page89/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page89/E2/1.jpg" }],

      [
        { url: "img/FriendsPlus/Page89/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page89/E2/3.jpg",
          audioUrl: "Audios/P89/hinh1.mp3",
        },
        { url: "img/FriendsPlus/Page89/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page89/E2/5.jpg",
          audioUrl: "Audios/P89/hinh2.mp3",
        },
        { url: "img/FriendsPlus/Page89/E2/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page89/E2/7.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P89-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page89/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page89/E3/2.jpg" }],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P89-E4",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page89/E4/Key/answerKey.png",
    inputSize: 100,
    checkUppercase: true,
    titleImage: "img/FriendsPlus/Page89/E4/5.jpg",
    questionImage: [],
    maxLength: 5,
    // hintBox: [
    //   {
    //     src: [
    //       "<s style='color: rgb(0 188 212)'>brushing my hair</s>",
    //       "washing the car",
    //       "making cakes",
    //       "taking photos",
    //     ],
    //     borderColor: "#468dcb",
    //     width: 600,
    //   },
    // ],
    questions: [
      {
        title: `
        
        <div style='display:flex; margin: 0px 0px 10px 50px'>
            <div style='margin-right:50px'>
              <img src='img/FriendsPlus/Page89/E4/1.jpg'>
              <div style=''>#are bags.</div>
            </div>        
            <div>
              <img src='img/FriendsPlus/Page89/E4/2.jpg'>
              <div style=''>#are pencils.</div>
            </div>        
                   
        </div>
        <div style='display:flex; margin: 0px 0px 10px 50px'>
            <div style='margin-right:50px'>
              <img src='img/FriendsPlus/Page89/E4/3.jpg'>
              <div style=''>#are pens.</div>
            </div>        
            <div>
              <img src='img/FriendsPlus/Page89/E4/4.jpg'>
              <div style=''>#are computers.</div>
            </div>        
                   
        </div>
        
        
        `,
        answer: ["Those", "These", "These", "Those"],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 12",
    id: "SB3V2-U12-P89-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page89/E5/1.jpg" }],
    ],
  },
};

export default json;
