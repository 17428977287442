import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB3V2-U2-P16-E1",
    audio: "Audios/P16/audio.e1.p16.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page16/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page16/E1/2.jpg",
          audioUrl: "Audios/P16/tieude.e1.p16.mp3",
        },
        { url: "img/FriendsPlus/Page16/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page16/E1/4.jpg",
          audioUrl: "Audios/P16/pen.mp3",
        },
        {
          url: "img/FriendsPlus/Page16/E1/5.jpg",
          audioUrl: "Audios/P16/eraser.mp3",
        },
        {
          url: "img/FriendsPlus/Page16/E1/6.jpg",
          audioUrl: "Audios/P16/pencil.mp3",
        },
        {
          url: "img/FriendsPlus/Page16/E1/7.jpg",
          audioUrl: "Audios/P16/pencil case.mp3",
        },
        {
          url: "img/FriendsPlus/Page16/E1/8.jpg",
          audioUrl: "Audios/P16/book.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB3V2-U2-P16-E2",
    audio: "Audios/P16/audio.e2.p16.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page16/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page16/E2/2.jpg",
          audioUrl: "Audios/P16/tieude.e2.p16.mp3",
        },
        { url: "img/FriendsPlus/Page16/E2/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page16/E1/4.jpg",
          audioUrl: "Audios/P16/pen.mp3",
        },
        {
          url: "img/FriendsPlus/Page16/E1/5.jpg",
          audioUrl: "Audios/P16/eraser.mp3",
        },
        {
          url: "img/FriendsPlus/Page16/E1/6.jpg",
          audioUrl: "Audios/P16/pencil.mp3",
        },
        {
          url: "img/FriendsPlus/Page16/E1/7.jpg",
          audioUrl: "Audios/P16/pencil case.mp3",
        },
        {
          url: "img/FriendsPlus/Page16/E1/8.jpg",
          audioUrl: "Audios/P16/book.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB3V2-U2-P16-E3",
    audio: "Audios/P16/audio.e3.p16.mp3",
    video: "/img/FriendsPlus/Page16/Video/Unit02.mp4",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page16/E3/1.jpg" },
        {
          url: "img/FriendsPlus/Page16/E3/2.jpg",
          audioUrl: "Audios/P16/tieude.e3.p16.mp3",
        },
        { url: "img/FriendsPlus/Page16/E3/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page16/E3/4.jpg",
          audioUrl: "Audios/P16/hinh1.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page16/E3/5.jpg",
          audioUrl: "Audios/P16/hinh2.e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page16/E3/6.jpg",
          audioUrl: "Audios/P16/hinh3.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page16/E3/7.jpg",
          audioUrl: "Audios/P16/hinh4.e3.mp3",
        },
      ],
    ],
  },
};

export default json;
