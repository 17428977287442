import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "SB3V2-U3-P23-E1",
    audio: "Audios/P23/audio.e1.p23.mp3",
    video: "/img/FriendsPlus/Page22/Video/Unit03.mp4",
    component: UI,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page23/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/2.jpg",
          audioUrl: "Audios/P22/tieude.e3.p22.mp3",
        },
        { url: "img/FriendsPlus/Page23/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page22/E3/4.jpg",
          audioUrl: "Audios/P22/hinh1.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page22/E3/5.jpg",
          audioUrl: "Audios/P22/hinh2.e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page22/E3/6.jpg",
          audioUrl: "Audios/P22/hinh3.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page22/E3/7.jpg",
          audioUrl: "Audios/P22/hinh4.e3.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 3",
    id: "SB3V2-U3-P23-E2",
    audio: "Audios/P23/audio.e2.p23.mp3",
    video: "",
    maxLength: 8,
    component: UI,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page23/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page23/E2/2.jpg",
          audioUrl: "Audios/P23/tieude.e2.p23.mp3",
        },
        { url: "img/FriendsPlus/Page23/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page23/E2/4.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 3",
    id: "SB3V2-U3-P23-E2",
    audio: "",
    video: "",
    maxLength: 5,
    component: D1,
    // textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page23/E3/Key/answerKey.png",

    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page23/E3/14.jpg" }],
      [{ url: "img/FriendsPlus/Page23/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page23/E3/2.jpg" },
        { url: "img/FriendsPlus/Page23/E3/3.jpg" },
        { url: "img/FriendsPlus/Page23/E3/4.jpg" },
        { url: "img/FriendsPlus/Page23/E3/5.jpg", input: true, answer: "on" },
        { url: "img/FriendsPlus/Page23/E3/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page23/E3/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page23/E3/8.jpg" },
        { url: "img/FriendsPlus/Page23/E3/9.jpg", input: true, answer: "in" },
        { url: "img/FriendsPlus/Page23/E3/10.jpg" },
        {
          url: "img/FriendsPlus/Page23/E3/11.jpg",
          input: true,
          answer: "under",
        },
        { url: "img/FriendsPlus/Page23/E3/12.jpg" },
      ],

      [{ url: "img/FriendsPlus/Page23/E3/13.jpg" }],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 3",
    id: "SB3V2-U3-P23-E3",
    audio: "",
    video: "",
    maxLength: 8,
    component: UI,

    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page23/E4/1.jpg" }],
    ],
  },
};

export default json;
