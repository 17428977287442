import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  4: {
    // Exercise num
    unit: "Review 3",
    id: "SB3V2-RV3-P73-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page73/E4/Key/answerKey.png",
    inputSize: 250,
    titleImage: "img/FriendsPlus/Page73/E4/1.jpg",
    questionImage: [],
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 20,
    hintBox: [
      {
        src: [
          "<s>brushing my hair</s>",
          "making cakes",
          "taking photos",
          "washing the car",
        ],
        borderColor: "#468dcb",
        width: 800,
      },
    ],
    questions: [
      {
        title: `
            <div style='margin-bottom:20px'><hintBox id='0'></hintBox></div>
            <div style='display:flex'>
             <div style='margin-right:114px'><b>1.</b> I am <u style="color: rgb(82,197,235);">&emsp;brushing my hair&emsp;</u></div>        
             <div style='margin-bottom:20px'><b>3. </b> My mom is#</div> 
            </div>        
            <div style='display:flex'> 
             <div style='margin-right: 50px'><b>2. </b> My dad is#</div>        
             <div style='margin-bottom:20px'><b>4.  </b> My sister is#</div>        
            </div>     
        
        
        
        `,
        answer: ["making cakes.", "washing the car.", "taking photos."],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Review 3",
    id: "SB3V2-U7-P73-E5",
    audio: "",
    video: "",
    component: UI,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page73/E5/1.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page73/E5/2.jpg" }],
    ],
  },
};

export default json;
