import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB3V2-U1-P15-E1",
    audio: "Audios/P15/audio.e1.p15.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page15/E1/Key/answerKey.png",
    component: D1,
    maxLength: 1,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page15/E1/20.jpg" },
        {
          url: "img/FriendsPlus/Page15/E1/21.jpg",
          audioUrl: "Audios/P15/tieude.e1.p15.mp3",
        },
        { url: "img/FriendsPlus/Page15/E1/22.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page15/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page15/E1/2.jpg" },
        { url: "img/FriendsPlus/Page15/E1/3.jpg", input: true, answer: "b" },
        { url: "img/FriendsPlus/Page15/E1/4.jpg" },
        { url: "img/FriendsPlus/Page15/E1/5.jpg", input: true, answer: "g" },
        { url: "img/FriendsPlus/Page15/E1/6.jpg" },
        { url: "img/FriendsPlus/Page15/E1/7.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page15/E1/8.jpg" },
        { url: "img/FriendsPlus/Page15/E1/9.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page15/E1/10.jpg" },
        { url: "img/FriendsPlus/Page15/E1/11.jpg", input: true, answer: "d" },
        { url: "img/FriendsPlus/Page15/E1/12.jpg" },
        { url: "img/FriendsPlus/Page15/E1/13.jpg", input: true, answer: "h" },
        { url: "img/FriendsPlus/Page15/E1/14.jpg" },
        { url: "img/FriendsPlus/Page15/E1/15.jpg", input: true, answer: "c" },
        { url: "img/FriendsPlus/Page15/E1/16.jpg" },
        { url: "img/FriendsPlus/Page15/E1/17.jpg", input: true, answer: "f" },
        { url: "img/FriendsPlus/Page15/E1/18.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page15/E1/19.jpg" }],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB3V2-U1-P15-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page15/E2/1.jpg" }],
      [{ url: "img/FriendsPlus/Page15/E2/2.jpg" }],
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB3V2-U1-P15-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page15/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page15/E3/2.jpg" }],
    ],
  },
  4: {
    unit: "Unit 1",
    id: "SB3V2-U1-P15-E4",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dash" },
    inputSize: 400,
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page15/E4/1.jpg' />
        <div style='margin: 10px 0px 0px 50px'><b>1.</b> My favorite toy is #.</div>
        <div style='margin: 10px 0px 0px 50px'><b>2.</b> My #.</div>
        
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB7-U1-P15-E5",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dash" },
    inputSize: 400,
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page15/E5/1.jpg' />
        <div style='margin: 10px 0px 0px 50px'><b>1.</b> Your favorite toy is #.</div>
        <div style='margin: 10px 0px 0px 50px'><b>2.</b> Your #.</div>
        
        `,
        answer: [],
      },
    ],
  },
};

export default json;
