import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 5",
    id: "SB3V2-U5-P42-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [{ url: "img/FriendsPlus/Page42/E1/1.jpg" }],
      [{ url: "img/FriendsPlus/Page42/E1/2.jpg" }],
      [
        { url: "img/FriendsPlus/Page42/E1/3.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/4.jpg",
          audioUrl: "Audios/P42/eggs.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/5.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/6.jpg",
          audioUrl: "Audios/P42/ice-cream.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page42/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/9.jpg",
          audioUrl: "Audios/P42/eggs.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/11.jpg",
          audioUrl: "Audios/P42/ice-cream.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/12.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/13.jpg",
          audioUrl: "Audios/P42/orange-juice.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/14.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page42/E1/15.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/16.jpg",
          audioUrl: "Audios/P42/eggs.mp3",
        },
        {
          url: "img/FriendsPlus/Page42/E1/17.jpg",
          audioUrl: "Audios/P42/tomatoes.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/18.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/19.jpg",
          audioUrl: "Audios/P42/ice-cream.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/20.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/21.jpg",
          audioUrl: "Audios/P42/orange-juice.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/22.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page42/E1/23.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/24.jpg",
          audioUrl: "Audios/P42/tomatoes.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/25.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/26.jpg",
          audioUrl: "Audios/P42/ice-cream.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/27.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/28.jpg",
          audioUrl: "Audios/P42/orange-juice.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/29.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page42/E1/30.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/31.jpg",
          audioUrl: "Audios/P42/grapes.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/32.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/33.jpg",
          audioUrl: "Audios/P42/orange-juice.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/34.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page42/E1/35.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/36.jpg",
          audioUrl: "Audios/P42/rice.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/37.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/38.jpg",
          audioUrl: "Audios/P42/grapes.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/39.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/40.jpg",
          audioUrl: "Audios/P42/apple.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/41.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page42/E1/42.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/43.jpg",
          audioUrl: "Audios/P42/carrots.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/44.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/45.jpg",
          audioUrl: "Audios/P42/grapes.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/46.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/47.jpg",
          audioUrl: "Audios/P42/milk.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/48.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page42/E1/49.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/50.jpg",
          audioUrl: "Audios/P42/carrots.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/51.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/52.jpg",
          audioUrl: "Audios/P42/banana.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/53.jpg" },
        {
          url: "img/FriendsPlus/Page42/E1/54.jpg",
          audioUrl: "Audios/P42/milk.mp3",
        },
        { url: "img/FriendsPlus/Page42/E1/55.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page42/E1/56.jpg" }],
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB3V2-U5-P42-E2",
    audio: "Audios/P42/audio-e2-p42.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page42/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page42/E2/2.jpg",
          audioUrl: "Audios/P42/tieude-e2-p42.mp3",
        },
        { url: "img/FriendsPlus/Page42/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page42/E2/4.jpg" }],
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB3V2-U5-P42-E3",
    audio: "",
    video: "",
    component: D1,
    fontSize: 18,
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page39/E4/Key/answerKey.png",
    maxLength: 100,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page42/E3/11.jpg" }],
      [{ url: "img/FriendsPlus/Page42/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page42/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page42/E3/3.jpg",
          input: true,
          answer: "meat,rice,yogurt,apple juice",
        },
        { url: "img/FriendsPlus/Page42/E3/4.jpg", input: true, answer: "milk" },
        { url: "img/FriendsPlus/Page42/E3/5.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page42/E3/6.jpg" },
        {
          url: "img/FriendsPlus/Page42/E3/7.jpg",
          input: true,
          answer: "yogurt,milk,banana",
        },
        {
          url: "img/FriendsPlus/Page42/E3/8.jpg",
          input: true,
          answer: "apple juice,ice cream",
        },
        {
          url: "img/FriendsPlus/Page42/E3/9.jpg",
        },
      ],
      [{ url: "img/FriendsPlus/Page42/E3/10.jpg" }],
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB3V2-U5-P42-E4",
    audio: "",
    video: "",
    component: T6,
    textareaStyle: { width: "100%" },
    hideBtnFooter: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page42/E4/1.jpg' />
          <textarea id="0" rows="5"></textarea>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
