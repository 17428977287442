import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 7",
    id: "SB3V2-U7-P58-E1",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 410,
    maxLength: 34,
    exerciseKey: "img/FriendsPlus/Page58/E1/Key/answerKey.png",
    // exerciseKey: "img/FriendsPlus/Page46/E3/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page58/E1/1.jpg'/>   ",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='margin-left: 5mm'>Where are the people?<br>#</div>
        <img src='img/FriendsPlus/Page58/E2/2.jpg' style='margin-top: -10px'/>
            
          `,
        answer: ["The people are at the bus station."],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-p58-E2",
    audio: "Audios/P57/audio-e2-p58.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page58/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page58/E2/2.jpg",
          audioUrl: "Audios/P57/tieude-e2-p58.mp3",
        },
        { url: "img/FriendsPlus/Page58/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page58/E2/4.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-p58-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page58/E3/Key/answerKey.png",
    component: D1,
    textAlign: "center",
    fontSize: 29,
    maxLength: 6,

    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page58/E3/11.jpg" }],
      [
        { url: "img/FriendsPlus/Page58/E3/1.jpg" },
        {
          url: "img/FriendsPlus/Page58/E3/2.jpg",
          input: true,
          answer: "small",
        },
        { url: "img/FriendsPlus/Page58/E3/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page58/E3/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page58/E3/5.jpg" },
        {
          url: "img/FriendsPlus/Page58/E3/6.jpg",
          input: true,
          answer: "people",
        },
        { url: "img/FriendsPlus/Page58/E3/7.jpg" },
        {
          url: "img/FriendsPlus/Page58/E3/8.jpg",
          input: true,
          answer: "shirt",
        },
        { url: "img/FriendsPlus/Page58/E3/9.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page58/E3/10.jpg" }],
      [{ url: "img/FriendsPlus/Page58/E2/4.jpg" }],
    ],
  },
};

export default json;
