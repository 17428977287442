import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P66-E1",
    audio: "Audios/P66/audio-e1-p66.mp3",
    video: "",
    component: D1,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page66/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page66/E1/b.jpg",
          audioUrl: "Audios/P66/tieude-e1-p66.mp3",
        },
        { url: "img/FriendsPlus/Page66/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page66/E2/1.jpg",
          audioUrl: "Audios/P66/play soccer.mp3",
        },
        { url: "img/FriendsPlus/Page66/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page66/E2/3.jpg",
          audioUrl: "Audios/P66/ride a bike.mp3",
        },
        { url: "img/FriendsPlus/Page66/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page66/E2/5.jpg",
          audioUrl: "Audios/P66/run.mp3",
        },
        { url: "img/FriendsPlus/Page66/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page66/E2/7.jpg",
          audioUrl: "Audios/P66/catch.mp3",
        },
        { url: "img/FriendsPlus/Page66/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page66/E2/9.jpg",
          audioUrl: "Audios/P66/fly.mp3",
        },
      ],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P66-E2",
    audio: "Audios/P66/audio-e2-p66.mp3",
    video: "",
    component: D1,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page66/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page66/E2/b.jpg",
          audioUrl: "Audios/P66/tieude-e2-p66.mp3",
        },
        { url: "img/FriendsPlus/Page66/E2/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page66/E2/1.jpg",
          audioUrl: "Audios/P66/play soccer.mp3",
        },
        { url: "img/FriendsPlus/Page66/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page66/E2/3.jpg",
          audioUrl: "Audios/P66/ride a bike.mp3",
        },
        { url: "img/FriendsPlus/Page66/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page66/E2/5.jpg",
          audioUrl: "Audios/P66/run.mp3",
        },
        { url: "img/FriendsPlus/Page66/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page66/E2/7.jpg",
          audioUrl: "Audios/P66/catch.mp3",
        },
        { url: "img/FriendsPlus/Page66/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page66/E2/9.jpg",
          audioUrl: "Audios/P66/fly.mp3",
        },
      ],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P66-E1",
    audio: "Audios/P66/audio-e3-p66.mp3",
    video: "/img/FriendsPlus/Page66/Video/Unit09.mp4",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page66/E3/a.jpg" },
        {
          url: "img/FriendsPlus/Page66/E3/b.jpg",
          audioUrl: "Audios/P66/tieude-e3-p66.mp3",
        },
        { url: "img/FriendsPlus/Page66/E3/c.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page66/E3/1.jpg",
          audioUrl: "Audios/P66/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E3/2.jpg",
          audioUrl: "Audios/P66/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page66/E3/3.jpg",
          audioUrl: "Audios/P66/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E3/4.jpg",
          audioUrl: "Audios/P66/hinh4.mp3",
        },
      ],
    ],
  },
};

export default json;
