import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    // Exercise num
    unit: "Review 3",
    id: "SB3V2-RV3-P72-E1",
    audio: "Audios/P72/audio-e1-p72.mp3",
    exerciseKey: "img/FriendsPlus/Page72/E1/Key/answerKey.png",
    video: "",
    component: DesignUnderLine,
    isAllowSubmit: true,
    totalInput: 5,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page72/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page72/E1/b.jpg",
          audioUrl: "Audios/P72/tieude-e1-p72.mp3",
        },
        { url: "img/FriendsPlus/Page72/E1/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page72/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page72/E1/2.jpg" },
        { url: "img/FriendsPlus/Page72/E1/3.jpg" },
        { url: "img/FriendsPlus/Page72/E1/4.jpg" },
        { url: "img/FriendsPlus/Page72/E1/5.jpg" },
        { url: "img/FriendsPlus/Page72/E1/6.jpg" },
        { url: "img/FriendsPlus/Page72/E1/7.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page72/E1/8.jpg" },
        { url: "img/FriendsPlus/Page72/E1/9.jpg", input: 1 },
        { url: "img/FriendsPlus/Page72/E1/10.jpg" },
        { url: "img/FriendsPlus/Page72/E1/11.jpg", input: 2 },
        { url: "img/FriendsPlus/Page72/E1/12.jpg" },
        { url: "img/FriendsPlus/Page72/E1/13.jpg", input: 2, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page72/E1/14.jpg" }],
      [
        { url: "img/FriendsPlus/Page72/E1/15.jpg" },
        { url: "img/FriendsPlus/Page72/E1/16.jpg", input: 3 },
        { url: "img/FriendsPlus/Page72/E1/17.jpg" },
        { url: "img/FriendsPlus/Page72/E1/18.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page72/E1/19.jpg" },
        { url: "img/FriendsPlus/Page72/E1/20.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page72/E1/21.jpg" },
        { url: "img/FriendsPlus/Page72/E1/22.jpg", input: 4 },
        { url: "img/FriendsPlus/Page72/E1/23.jpg" },
        { url: "img/FriendsPlus/Page72/E1/24.jpg", input: 5 },
        { url: "img/FriendsPlus/Page72/E1/25.jpg" },
        { url: "img/FriendsPlus/Page72/E1/26.jpg", input: 5, isCorrect: true },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Review 3",
    id: "SB3V2-RV3-P72-E2",
    audio: "",
    video: "",
    component: D1,
    isAllowSubmit: true,
    checkDuplicated: true,
    maxLength: 1,
    exerciseKey: "img/FriendsPlus/Page72/E2/Key/answerKey.png",
    totalInput: 5,

    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page72/E2/14.jpg" }],
      [{ url: "img/FriendsPlus/Page72/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page72/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page72/E2/3.jpg",
          input: true,
          answer: "A / C",
        },
        { url: "img/FriendsPlus/Page72/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page72/E2/5.jpg",
          input: true,
          answer: "C / A",
        },
        { url: "img/FriendsPlus/Page72/E2/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page72/E2/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page72/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page72/E2/9.jpg",
          input: true,
          answer: "B / D",
        },
        { url: "img/FriendsPlus/Page72/E2/10.jpg" },
        {
          url: "img/FriendsPlus/Page72/E2/11.jpg",
          input: true,
          answer: "D / B",
        },
        { url: "img/FriendsPlus/Page72/E2/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page72/E2/13.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Review 3",
    id: "SB3V2-RV3-P72-E3",
    audio: "",
    video: "",
    component: DesignUnderLine,
    isAllowSubmit: true,
    exerciseKey: "img/FriendsPlus/Page72/E3/Key/answerKey.png",
    totalInput: 3,

    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page72/E3/16.jpg" }],
      [{ url: "img/FriendsPlus/Page72/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page72/E3/2.jpg" },
        { url: "img/FriendsPlus/Page72/E3/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page72/E3/4.jpg" },
        { url: "img/FriendsPlus/Page72/E3/5.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page72/E3/6.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page72/E3/7.jpg" },
        { url: "img/FriendsPlus/Page72/E3/8.jpg", input: 2 },
        { url: "img/FriendsPlus/Page72/E3/9.jpg" },
        { url: "img/FriendsPlus/Page72/E3/10.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page72/E3/11.jpg" },
        { url: "img/FriendsPlus/Page72/E3/12.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page72/E3/13.jpg" },
        { url: "img/FriendsPlus/Page72/E3/14.jpg", input: 3 },
        { url: "img/FriendsPlus/Page72/E3/15.jpg" },
      ],
    ],
  },
};

export default json;
