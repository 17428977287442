import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  // 1: {
  //   unit: "Unit 2",
  //   id: "SB3V2-U2-P21-E1",
  //   audio: "Audios/P21/audio.e1.p21.mp3",
  //   exerciseKey: "img/FriendsPlus/Page21/E1/Key/answerKey.png",
  //   video: "",
  //   component: D1,
  //   maxLength: 1,
  //   questionImage: [
  //     [
  //       { url: "img/FriendsPlus/Page21/E1/14.jpg" },
  //       {
  //         url: "img/FriendsPlus/Page21/E1/15.jpg",
  //         audioUrl: "Audios/P21/tieude.e1.p21.mp3",
  //       },
  //       { url: "img/FriendsPlus/Page21/E1/16.jpg" },
  //     ],
  //     [{ url: "img/FriendsPlus/Page21/E1/1.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page21/E1/2.jpg" },
  //       { url: "img/FriendsPlus/Page21/E1/3.jpg", input: true, answer: "b" },
  //       { url: "img/FriendsPlus/Page21/E1/4.jpg" },
  //       { url: "img/FriendsPlus/Page21/E1/5.jpg", input: true, answer: "b" },
  //       { url: "img/FriendsPlus/Page21/E1/6.jpg" },
  //     ],
  //     [{ url: "img/FriendsPlus/Page21/E1/7.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page21/E1/8.jpg" },
  //       { url: "img/FriendsPlus/Page21/E1/9.jpg", input: true, answer: "a" },
  //       { url: "img/FriendsPlus/Page21/E1/10.jpg" },
  //       { url: "img/FriendsPlus/Page21/E1/11.jpg", input: true, answer: "b" },
  //       { url: "img/FriendsPlus/Page21/E1/12.jpg" },
  //     ],
  //     [{ url: "img/FriendsPlus/Page21/E1/13.jpg" }],
  //   ],
  // },
  1: {
    unit: "Unit 2",
    id: "SB3V2-U2-P21-E1",
    audio: "Audios/P21/audio.e1.p21.mp3",
    exerciseKey: "img/FriendsPlus/Page21/E1/Key/answerKey.png",
    component: T6,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 25,
      height: 40,
      border: "2px solid green",
      borderRadius: 10,
      textAlign: "center",
    },
    isHiddenCheck: true,
    inputSize: 50,
    textAlign: "center",
    maxLength: 1,
    questionImage: [],
    questions: [
      {
        title: `
        <div style='position: relative'>
        <img src='img/FriendsPlus/Page21/E1/1.jpg' />
        <div style='position:absolute; top:260px ;left:46px'><span style='font-size: 25px; border: 2px solid green; border-radius: 10px; padding: 3px 5px; color: gray'><i>&ensp;b&ensp;</i></span></div>
        <div style='position:absolute; top:505px ;left:42px'><input id='1'></div>
        <div style='position:absolute; top:260px ;left:698px'><input id='2'></div>
        <div style='position:absolute; top:505px ;left:698px'><input id='3'></div>
        </div>
      
        
        
        `,
        answer: ["a", "b", "b"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB3V2-U2-P21-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page21/E2/1.jpg" }],
      [{ url: "img/FriendsPlus/Page21/E2/2.jpg" }],
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB3V2-U2-P21-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dash" },
    inputSize: 100,
    textAlign: "center",
    maxLength: 6,
    exerciseKey: "img/FriendsPlus/Page21/E3/Key/answerKey.png",
    questionImage: [],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page21/E3/1.jpg' />
      
        <div style='display: flex; margin-top: 30px'>
            <div style='margin: 0px 230px 0px 30px'><b>1.</b><img src='img/FriendsPlus/Page21/E3/2.jpg' /></div>
            <div style='margin-top: 15px'>This is his <img src='img/FriendsPlus/Page21/E3/7.jpg' /> . </div>
        </div>
        <div style='display: flex; margin-top: 20px'>
            <div style='margin: 0px 100px 0px 30px'><b>2.</b><img src='img/FriendsPlus/Page21/E3/3.jpg' /></div>
            <div style='margin-top: 15px'>That is #.</div>
        </div>
        <div style='display: flex; margin-top: 20px'>
            <div style='margin: 0px 103px 0px 30px'><b>3.</b><img src='img/FriendsPlus/Page21/E3/4.jpg' /></div>
            <div style='margin-top: 25px'>That is her #.</div>
        </div>
        <div style='display: flex; margin-top: 20px '>
            <div style='margin: 0px 132px 0px 30px '><b>4.</b><img src='img/FriendsPlus/Page21/E3/5.jpg' /></div>
            <div style='margin-top: 40px'>This is #. </div>
        </div>
        
        
        `,
        answer: ["eraser", "pencil", "book"],
      },
    ],
  },
};

export default json;
