import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P70-E1",
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page70/E1/Key/answerKey.png",
    inputSize: 400,
    titleImage: "img/FriendsPlus/Page70/E1/1.jpg",
    questionImage: [],
    questions: [
      {
        title: `
        
        <div>Where’s Nam? </div>#
        <img src='img/FriendsPlus/Page70/E2/2.jpg'>
        
        `,
        answer: ["Nam is on vacation in Nha Trang"],
      },
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P70-E2",
    audio: "Audios/P70/audio-e2-p70.mp3",
    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page70/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page70/E2/b.jpg",
          audioUrl: "Audios/P70/tieude-e2-p70.mp3",
        },
        { url: "img/FriendsPlus/Page70/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page70/E2/2.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 9",
    id: "SB5-U9-P70-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page70/E3/Key/answerKey.png",
    inputSize: 70,
    textAlign: "center",
    maxLength: 3,
    titleImage: "img/FriendsPlus/Page70/E3/1.jpg",
    questionImage: [],
    questions: [
      {
        title: `
        
        <div style='margin-bottom:10px'><b>1.</b>Nam is on vacation in Da Nang. #</div>
        <div style='margin-bottom:10px'><b>2.</b>Nam can play soccer. #</div>
        <div style='margin-bottom:10px'><b>3.</b>Hoa can’t catch a ball. #</div>
        <div><b>4.</b>Hoa can run. #</div>
        
        `,
        answer: ["No", "Yes", "No", "Yes"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P70-E4",
    audio: "",
    video: "",
    component: T6,
    // exerciseKey: "img/FriendsPlus/Page70/E3/Key/answerKey.png",
    inputSize: 100,
    textareaStyle: { width: 700 },
    titleImage: "img/FriendsPlus/Page70/E4/1.jpg",
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
            
            
            <div><textarea id="0" rows="8"></textarea></div>
            
            `,
        answer: ["", "", "", ""],
      },
    ],
  },
};

export default json;
