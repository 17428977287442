import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P86-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page86/E1/1.jpg" }],
      [{ url: "img/FriendsPlus/Page86/E2/2.jpg" }],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P86-E2",
    audio: "Audios/P86/audio-e2-p86.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page86/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page86/E2/b.jpg",
          audioUrl: "Audios/P86/tieude-e2-p86.mp3",
        },
        { url: "img/FriendsPlus/Page86/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page86/E2/2.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P86-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page86/E3/Key/answerKey.png",
    inputSize: 40,
    titleImage: "img/FriendsPlus/Page86/E3/1.jpg",
    questionImage: [],
    maxLength: 1,
    // hintBox: [
    //   {
    //     src: [
    //       "<s style='color: rgb(0 188 212)'>brushing my hair</s>",
    //       "washing the car",
    //       "making cakes",
    //       "taking photos",
    //     ],
    //     borderColor: "#468dcb",
    //     width: 600,
    //   },
    // ],
    questions: [
      {
        title: `
        <div  style='display:flex;margin:20px'>
          <div style='margin:'><b>1.</b> There are twelve chairs.</div>        
          <div style='margin-right: 50px'>#</div>        
          <div style='margin-right:'><b>4. </b> There are flowers on the cabinet.</div>        
          <div>#</div>        
        </div>
        <div  style='display:flex;margin:20px'>
          <div style='margin-right: 36px'><b>2. </b> There are two tables.</div>        
          <div style='margin-right: 50px'>#</div>        
          <div style='margin-right:142px'><b>5. </b> Vinh has ten books.</div>        
          <div>#</div>        
        </div>
        <div  style='display:flex;margin:20px'>
          <div style='margin-right:114px'><b>3. </b> There is a TV.</div>        
          <div style='margin-right: 50px'>#</div>        
          <div style='margin-right:67px'><b>6. </b> The books are about bugs.</div>        
          <div>#</div>        
        </div>
        
        
        
        
        `,
        answer: ["F", "F", "F", "F", "T", "F"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P86-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page70/E3/Key/answerKey.png",
    inputSize: 100,
    hideBtnFooter: true,
    titleImage: "img/FriendsPlus/Page86/E4/1.jpg",
    questionImage: [],
    textareaStyle: { width: 700 },
    // hintBox: [
    //   {
    //     src: [
    //       "<s style='color: rgb(0 188 212)'>brushing my hair</s>",
    //       "washing the car",
    //       "making cakes",
    //       "taking photos",
    //     ],
    //     borderColor: "#468dcb",
    //     width: 600,
    //   },
    // ],
    questions: [
      {
        title: `
        
       
        <div style=''>
                    
            <textarea id="0" rows="8"></textarea>       
                   
        </div>
        
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
